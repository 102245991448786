import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/navbar/Navbar";
import ProductSolutions from "./pages/ProductSolutions";
import Partners from "./pages/Partners";
import Quality from "./pages/Quality";
import News from "./pages/News";
import AboutUs from "./pages/AboutUs";
import NewDetails from "./pages/NewDetails";
import ProductDetail from "./pages/ProductDetail";

function App() {
  return (
      <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/quality" element={<Quality />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/details/:id" element={<NewDetails />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route
          path="/products-and-solutions/:productType"
          element={<ProductSolutions />}
        />
        <Route
          path="/products-and-solutions/details/:productId"
          element={<ProductDetail />}
        />
      </Routes>
    </>
  );
}

export default App;
