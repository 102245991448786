import React from "react";
import qualityBanner456 from "../assets/images/quality45646.png";
import Footer from "../components/footer/Footer";
import PDF1 from "../assets/pdf/ISO9001-2015.pdf";
import PDF2 from "../assets/pdf/ISO13485-2016.pdf";
import { PiFilePdf } from "react-icons/pi";

const Quality = () => {
  return (
    <div className="bg-lightBody max-md:overflow-hidden">
      <div className="w-full h-[13.625rem] max-xl:h-[13rem] max-md:h-[10rem] relative mt-5 max-md:mt-1">
        <img
          src={qualityBanner456}
          alt=""
          className="w-full h-full object-cover absolute"
          style={{ objectPosition: "0% 65%" }}
        />
        <div className="absolute w-full h-full top-0 left-0 flex flex-col items-center justify-center text-white z-10">
          <div className="text-7xl max-xl:text-6xl max-md:text-5xl font-semibold">
            Quality
          </div>
        </div>
        <div className="absolute bg-black w-full h-full top-0 left-0 opacity-[50%]"></div>
      </div>

      <div className="text-justify max-md:text-left px-56 max-xl:px-32 max-md:px-5 pt-14 max-md:pt-7 pb-7 max-xl:pb-6 max-md:pb-5 text-lg max-md:text-base leading-normal">
        <h2 className="font-bold mb-2">Quality Policy</h2>
        Diagnotech Biyoteknoloji ve Yaşam Bilimleri A.Ş. is committed to
        providing consistent and high-standard products and services to meet the
        needs of customers, suppliers, authorities, society and the environment.
        We aim to produce our products on time, in accordance with national and
        international quality standards, focused on customer satisfaction based
        on innovative and continuous improvement. We produce and monitor our
        products in accordance with legal regulations by training and educating
        all our employees. <br /> <br /> Diagnotech is a leading biotechnology
        company, offering products and complete laboratory solutions. Based on
        our principle of <b>Knowing Matters</b>, product safety and quality are
        our top priorities. Thanks to these priorities, our main goal is to
        provide high-quality, safe and effective products and services to meet
        the high standards of the diagnostic sector in global markets.
        <ul className="list-disc pl-10 max-md:pl-4 my-4 flex flex-col gap-3 leading-snug">
          <li>
            The ISO Quality Management Standard is implemented throughout our
            organization and verified by independent third-party certification
            bodies.
          </li>
          <li>
            The “IVD CE” mark on our products indicates that they are produced
            in accordance with international standards.
          </li>
          <li>
            The quality in all of our activities regarding the development of
            new products and the, improvement of existing products and services,
            including the management of new product projects, is proven by the
            efforts of our successful team of R&D professionals.
          </li>
          <li>
            High-quality standards that patients and the public can trust, and
            compliance with relevant laws and regulations are our preferences.
          </li>
          <li>
            Diagnotech ensures the quality and performance of its products in
            accordance with EN ISO 13485:2016 and ISO 9001:2015 standards and by
            fulfilling the obligations arising from the requirements of the
            98/79/EC IVD Directive.
          </li>
        </ul>
        Transition work on the EU 2017/746 IVDR In Vitro Diagnostic Medical
        Device Regulation continues.
        <div className="flex flex-col my-4 gap-1">
          <a
            href={PDF1}
            download
            className="flex items-center gap-1 hover:scale-105 w-fit duration-300"
          >
            <PiFilePdf size={22} />
            <div className="text-lg font-medium text-cyan-500">
              ISO 9001-2015 <span className="text-sm">(Download PDF)</span>
            </div>
          </a>
          <a
            href={PDF2}
            download
            className="flex items-center gap-1 hover:scale-105 w-fit duration-300"
          >
            <PiFilePdf size={22} />
            <div className="text-lg font-medium text-cyan-500">
              ISO 13485-2016 <span className="text-sm">(Download PDF)</span>
            </div>
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Quality;
