import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useParams } from "react-router-dom";

const MediaItem = ({ image, text, id, date }) => {
  return (
    <div className="flex flex-1 flex-col items-start max-md:items-center justify-between max-md:justify-center gap-5 max-md:gap-2">
      <Link to={`/news/details/${id}`}>
        <div className="w-80 h-48 max-xl:w-64 max-xl:h-36 max-md:w-full rounded-2xl overflow-hidden cursor-pointer">
          <img
            src={image}
            alt=""
            className="w-full h-full shadow-lg shadow-gray-400 object-cover hover:scale-125 max-md:hover:scale-100 duration-300"
          />
        </div>
      </Link>
      <div className="text-lightBlue text-xl max-xl:text-lg max-md:text-base pl-1 max-md:pl-0">
        {date}
      </div>
      <div className="text-lightBlue text-xl max-xl:text-lg max-md:text-base font-bold fbold w-4/5 max-md:w-[95%] max-xl:w-[90%] pl-1 max-md:pl-0 max-md:text-center max-md:-mt-1">
        {text}
      </div>
      <div className="w-3/4 max-xl:w-[90%] max-md:w-[95%] max-md:pl-0 pl-1">
        <div className="flex justify-between items-center text-lightBlue">
          <Link
            to={`/news/details/${id}`}
            className="text-lg max-md:text-base cursor-pointer hover:scale-95 duration-300"
          >
            Read More
          </Link>
          <div>
            <Link to={`/news/details/${id}`}>
              <FontAwesomeIcon
                icon={faArrowRightLong}
                className="text-[22px] max-md:text-[20px]"
              />
            </Link>
          </div>
        </div>
        <div className="h-[2px] max-md:h-[1px] bg-lightBlue" />
      </div>
    </div>
  );
};

export default MediaItem;
