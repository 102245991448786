import React from "react";
import bannerImg from "../../assets/images/3D.png";
import dImage from "../../assets/symbols/deatils.png";
import "../../style/banner.css";

const Banner = () => {
  return (
    <div
      className="flex items-center justify-between max-md:flex-col px-14 max-md:px-1 w-full backdrop-brightness-[0.98] bg-[#d3d1d1]"
      style={{
        boxShadow: " 0px 7px 50px 40px #d3d1d1",
      }}
    >
      <div className="flex flex-col w-1/2 max-md:w-full max-md:text-center gap-2 max-md:gap-1]">
        <div className="font-light text-xl max-xl:text-lg text-black">
          DISCOVER THE PRECISION OF DIAGNOSTICS
        </div>
        <div className="text-5xl max-xl:text-4xl max-md:text-3xl font-medium ml-[-3px]">
          Advancing Life Sciences Solutions Through Diagnotech
        </div>
        <div className="text-lg max-md:text-base mt-1 max-md:mt-0">
          Welcome to <b className="text-darkBlue">Diagnotech</b>, explore our
          commitment to innovation as we strive to revolutionize diagnostics and
          shape the future of life sciences.
        </div>
      </div>
      <div
        className="bg-center bg-cover max-md:bg-contain bg-no-repeat flex w-[55%] max-md:w-[160%] h-96 max-md:h-[350px] relative"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/3D.png)`,
        }}
        // style={{ backgroundImage: `url(${bannerImg})` }}
      >
        <div className="w-[42%] max-xl:w-[50%] max-md:w-[25%] h-[40%] max-md:h-[55%] rounded-2xl overflow-hidden px-4 max-md:px-2 pr-6 py-2 absolute top-1/2  right-3 max-md:right-[20%] max-xl:-right-1 -translate-y-1/2 max-md:-translate-y-0 flex flex-col justify-center backdrop-blur-[5px] backdrop-brightness-[1.1] bg-slate-100 bg-opacity-40 shadow-white shadow-inner">
          {/* <span className="font-semibold text-xl mb-1">Diagnotech</span> */}
          {/* <div className="bg-black w-2/3 max-md:w-full h-[3px] max-md:h-[1px] rounded-2xl" /> */}
          <span className="text-sm mt-1 max-md:text-[12px]">
            Convenience of an end-to-end solution provider, ensuring efficiency
            and excellence at every step of your scientific journey.
          </span>
        </div>
        <div className="absolute w-2/6 max-md:w-[30%] max-xl:w-[30%] max-xl:bottom-5 max-2xl:bottom-0 left-12 max-md:left-20 max-md:ml-4 max-md:-bottom-2 -bottom-10 animate-spin-dImage">
          <img src={dImage} alt="" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
