import React from "react";
import AboutBanner from "../components/about-us/AboutBanner";
import CardSlides from "../components/about-us/CardSlides";
import MissionVission from "../components/about-us/MissionVission";
import Map from "../components/about-us/Map";
import Footer from "../components/footer/Footer";

const AboutUs = () => {
  return (
    <div className="max-md:overflow-hidden">
      <AboutBanner />
      <CardSlides />
      <MissionVission />
      <Map />
      <Footer />
    </div>
  );
};

export default AboutUs;
