import React, { useEffect, useState } from 'react';
import MapChart from "./MapChart";

const Map = () => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://cms.diagnotech.com.tr/Api/GetDistributors');
        const data = await response.json();

        const mappedCountries = data.map((item, index) => ({
          id: index + 1,
          position: [parseFloat(item.lat), parseFloat(item.lng)],
          country: item.Title,
          company: item.Title,
          web: item.Web,
          tel: item.TelNo,
          mail: item.Mail,
          adress: item.Address, 
        }));
        setCountries(mappedCountries);
      } catch (error) {
        
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <MapChart countries={countries} />
    </div>
  );
};

export default Map;
