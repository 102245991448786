import React, { useEffect, useState } from "react";
import symbl from "../../assets/symbols/beyaz-çizgi.png";
import mission from "../../assets/symbols/mission.png";
import vision from "../../assets/symbols/vission.png";

const MissionVission = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Pencere yeniden boyutlandırıldığında bu işlev çağrılır.
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Component yüklendiğinde bir event listener ekleyin.
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Component kaldırıldığında event listener'ı temizleyin.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Pencerenin genişliğine bağlı olarak uygulanacak stil.
  const backgroundStyle = {
    backgroundImage: `url(${mission}), url(${vision})`,
    backgroundPosition: "50% 75%, 50% 25%",
    backgroundSize: "98%,98%",
    backgroundRepeat: "no-repeat, no-repeat",
  };
  return (
    <div className="flex flex-col items-center justify-center bg-[#4bb7cf] text-white overflow-hidden">
      <div
        className="flex flex-col items-center justify-center gap-4 w-full py-4 max-xl:py-0 relative custom-bg"
        // style={windowWidth >= 768 ? backgroundStyle : {}}
      >
        <div className="text-8xl max-xl:text-7xl max-md:text-5xl">Vision</div>
        <div className="text-center font-medium max-xl:px-12 max-md:px-4">
          Diagnotech aims to gather global Life Sciences professionals ranging
          from academic researchers, industry specialists, and routine
          <br className="max-md:hidden" />
          healthcare officials in one pot and transform this power into
          producing smart and reliable IVD & research tools, products, and
          complete <br className="max-md:hidden" /> laboratory solutions that
          are available on a global scale.  <br /> <br />
          Diagnotech delivers innovative ideas along with the highest quality
          production infrastructure to global markets with regards to a
          <br className="max-md:hidden" />
          professional marketing understanding and the most qualified and
          practical way. Those who want to contribute to this platform are more
          <br className="max-md:hidden" />
          than welcome to come side by side to combine such power to enhance the
          patients’ lives at their most and create a better future for
          <br className="max-md:hidden" />
          humankind on a win-to-win scale.
        </div>

        <div className="w-1/3 max-md:w-full scale-90 max-md:scale-95 -mt-44 max-2xl:-mt-36 -mb-40 max-2xl:-mb-36 max-xl:-mb-32 max-md:-mb-36 max-xl:-mt-28 max-md:-mt-36">
          <img src={symbl} alt="" className="w-full" />
        </div>
        <div className="text-8xl mt-0 max-xl:text-7xl max-md:text-5xl max-xl:mt-0 max-md:mt-0">
          Mission
        </div>
        <div className="text-center font-medium max-md:px-4">
          We are determined to lead the way as a highly respected and diverse
          company that provides innovative solutions,
          <br className="max-md:hidden" /> services, and value to clients and
          communities on a global scale by combining the most creative ideas and
          <br className="max-md:hidden" /> the related infrastructure required.
        </div>
      </div>
      <div className="bg-lightBlue w-[100vw] py-12 max-md:py-6 mt-20 max-md:mt-8" />
      <div className="my-12 text-8xl max-xl:text-7xl max-md:text-5xl max-xl:my-10 max-md:my-8">
        Distributors
      </div>
    </div>
  );
};

export default MissionVission;
