import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../style/cardSlides.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper/modules";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const CardSlides = () => {
  const swiperRef = useRef(null);
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [apiData, setApiData] = useState([]);

  const updateSlidesPerView = () => {
    if (window.innerWidth <= 768) {
      setSlidesPerView(1);
    } else {
      setSlidesPerView(3);
    }
  };

  useEffect(() => {
    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);
    fetch("https://cms.diagnotech.com.tr/Api/WithNumbersWe") 
      .then((response) => response.json())
      .then((data) => setApiData(data))
      .catch((error) => console.error("Error fetching data:", error));
    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  return (
    <div className="bg-[#e5e5e5] relative">
      <div className="about-us px-20 max-md:px-7 max-xl:px-10 py-10 max-md:py-7">
        <Swiper
          ref={swiperRef}
          slidesPerView={slidesPerView}
          spaceBetween={50}
          pagination={{
            clickable: true,
          }}
          loop
          className="mySwiper"
          navigation={false}
          modules={[Navigation]}
        >
          {apiData.map((item, index) => (
            <SwiperSlide
              key={item.Title}
              className="rounded-sm shadow-lg shadow-gray-400"
            >
              <div className="h-[25vh] max-xl:h-[23vh] text-lightBlue flex flex-col items-start justify-start pt-6 px-6 max-md:px-2 max-md:pt-2">
                <div className="text-5xl max-xl:text-4xl max-md:text-3xl">
                  {item.Content}
                </div>
                <div className="my-5 max-xl:my-4 bg-lightBlue h-[1px] w-full" />
                <div className="text-2xl max-xl:text-xl max-md:text-lg">
                  {item.Title}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="items-center justify-center gap-8 py-8 max-xl:gap-4 max-xl:py-5 max-md:py-4 hidden max-md:flex">
          <button
            className="bg-lightBlue rounded-full p-1 shadow-lg shadow-gray-400 duration-300 active:scale-90"
            onClick={() => {
              if (swiperRef.current && swiperRef.current.swiper) {
                swiperRef.current.swiper.slidePrev();
              }
            }}
          >
            <FiChevronLeft color="white" size={34} />
          </button>

          <button
            className="bg-lightBlue rounded-full p-1 shadow-lg shadow-gray-400 duration-300 active:scale-90"
            onClick={() => {
              if (swiperRef.current && swiperRef.current.swiper) {
                swiperRef.current.swiper.slideNext();
              }
            }}
          >
            <FiChevronRight color="white" size={35} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardSlides;
