import React, { useRef, useState } from "react";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { AiOutlinePauseCircle } from "react-icons/ai";
import video from "../../assets/video/video.mp4";
import imgForVideo from "../../assets/images/parents-teaching-child-to-bake-pie-2022-09-01-23-28-41-utc.jpg";

const VideoSection = () => {
  const [videoPlaying, setVideoPlaying] = React.useState(false);
  const [hasStarted, setHasStarted] = React.useState(false);
  const [videoCompleted, setVideoCompleted] = React.useState(false);
  const vidRef = React.useRef();

  const handleVideo = () => {
    if (!hasStarted) {
      setHasStarted(true);
    }

    if (videoPlaying) {
      vidRef.current.pause();
      setVideoPlaying(false);
    } else {
      if (videoCompleted) {
        vidRef.current.currentTime = 0; // Videoyu başa al
        setVideoCompleted(false);
      }
      vidRef.current.play();
      setVideoPlaying(true);
    }
  };

  const handleVideoEnd = () => {
    setVideoPlaying(false);
    setVideoCompleted(true);
  };

  return (
    <div className="h-[65vh] max-md:h-[30vh] relative">
      <video
        className="w-full h-full object-cover"
        src={video}
        ref={vidRef}
        type="video/mp4"
        controls={false}
        onEnded={handleVideoEnd}
      />

      {(!hasStarted || videoCompleted) && (
        <>
          <img
            src={imgForVideo}
            alt="Video Thumbnail"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
          <div className="z-10 text-6xl max-md:text-2xl max-md:w-full max-md:text-center text-white absolute top-[40%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            Knowing Matters
          </div>
        </>
      )}

      <div className="flex items-center justify-center absolute inset-0 bg-[rgba(0,0,0,0.4)]">
        <div
          className="flex flex-col mt-32 max-md:mt-16 justify-center cursor-pointer"
          onClick={handleVideo}
        >
          {videoPlaying ? (
            <AiOutlinePauseCircle
              color="#fff"
              fontSize={50}
              className="opacity-50 hover:opacity-100"
            />
          ) : (
            <AiOutlinePlayCircle color="#fff" fontSize={50} />
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
