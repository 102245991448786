import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Products = () => {
  return (
    <div className="px-28 max-2xl:px-20 max-xl:px-16 flex max-md:grid max-md:grid-cols-2 max-md:px-4 max-md:gap-y-14 justify-between items-center max-md:ml-12 pb-20 max-md:pb-0">
      <Link to={"/products-and-solutions/real-time-pcr-(qpcr)-kits"}>
        <div className="relative w-48 h-48 text-9xl max-2xl:text-[114px] max-2xl:w-[170px] max-xl:text-[90px] max-md:w-32 max-md:h-32 max-md:text-7xl max-2xl:h-[170px] max-xl:w-36 max-xl:h-36 font-medium text-[#CAC4CE]">
          01
          <div className="hover:scale-105 duration-300 font-semibold text-lg max-md:text-sm text-white bg-lightBlue w-48 h-48 max-2xl:w-[170px] max-2xl:h-[170px] max-xl:w-36 max-xl:h-36 max-md:w-32 max-md:h-32 rounded-full flex items-center justify-center text-center absolute top-[70px] max-2xl:top-[55px] max-xl:top-[48px] max-md:top-[40px] -left-7 right-0 shadow-lg shadow-darkBlue">
            MOLECULAR TESTING KITS
          </div>
        </div>
      </Link>
      <Link to={"/products-and-solutions/dna-seq-bundle"}>
        <div className="relative w-48 h-48 max-md:w-32 max-md:h-32 max-md:text-7xl text-9xl max-2xl:text-[112px] max-2xl:w-[170px] max-xl:text-[90px] max-2xl:h-[170px] max-xl:w-36 max-xl:h-36 font-medium text-[#CAC4CE]">
          02
          <div className="hover:scale-105 duration-300 p-2 font-semibold text-lg max-md:text-sm max-md:p-0 text-white bg-lightBlue w-48 h-48 max-md:w-32 max-md:h-32 max-md:top-[40px] max-2xl:w-[170px] max-2xl:h-[170px] max-xl:w-36 max-xl:h-36 rounded-full flex items-center justify-center text-center absolute top-[70px] max-2xl:top-[55px] max-xl:top-[48px] -left-5 max-md:-left-4 max-xl:-left-3 right-0 shadow-lg shadow-darkBlue">
            NGS KITS AND SERVICES
          </div>
        </div>
      </Link>
      <Link to={"/products-and-solutions/raw-materıals-and-components"}>
        <div className="relative w-48 h-48 max-md:w-32 max-md:h-32 max-md:text-7xl text-9xl max-2xl:text-[112px] max-2xl:w-[170px] max-xl:text-[90px] max-2xl:h-[170px] max-xl:w-36 max-xl:h-36 font-medium text-[#CAC4CE]">
          03
          <div className="hover:scale-105 duration-300 font-semibold text-lg max-md:text-sm text-white bg-lightBlue w-48 h-48 max-md:w-32 max-md:h-32 max-md:top-[40px] max-2xl:w-[170px] max-2xl:h-[170px] max-xl:w-36 max-xl:h-36 rounded-full flex items-center justify-center text-center absolute top-[70px] max-2xl:top-[55px] max-xl:top-[48px] -left-3 max-md:-left-4 right-0 shadow-lg shadow-darkBlue">
            RAW <br /> MATERIALS AND COMPONENTS
          </div>
        </div>
      </Link>
      <Link to={"/products-and-solutions/poınt-of-care-(poc)-solutıons"}>
        <div className="relative w-48 h-48 max-md:w-32 max-md:h-32 max-md:text-7xl text-9xl max-2xl:text-[112px] max-2xl:w-[170px] max-xl:text-[90px] max-2xl:h-[170px] max-xl:w-36 max-xl:h-36 font-medium text-[#CAC4CE]">
          04
          <div className="hover:scale-105 duration-300 font-semibold text-lg max-md:text-sm text-white bg-lightBlue w-48 h-48 max-md:w-32 max-md:h-32 max-md:top-[40px] max-2xl:w-[170px] max-2xl:h-[170px] max-xl:w-36 max-xl:h-36 rounded-full flex items-center justify-center text-center absolute top-[70px] max-2xl:top-[55px] max-xl:top-[48px] -left-3 right-0 shadow-lg shadow-darkBlue">
            POINT OF CARE (POC) SOLUTIONS
          </div>
        </div>
      </Link>
      <Link to={"/products-and-solutions/customızed-kıts-and-solutıons"}>
        <div className="relative w-48 h-48 max-md:w-32 max-md:h-32 max-md:text-7xl text-9xl max-2xl:text-[112px] max-2xl:w-[170px] max-xl:text-[90px] max-2xl:h-[170px] max-xl:w-36 max-xl:h-36 font-medium text-[#CAC4CE]">
          05
          <div className="hover:scale-105 duration-300 font-semibold text-lg max-md:text-sm text-white bg-lightBlue w-48 h-48 max-md:w-32 max-md:h-32 max-md:top-[40px] max-2xl:w-[170px] max-2xl:h-[170px] max-xl:w-36 max-xl:h-36 rounded-full flex items-center justify-center text-center absolute top-[70px] max-2xl:top-[55px] max-xl:top-[48px] -left-3 max-md:-left-4 right-0 shadow-lg shadow-darkBlue">
            CUSTOMIZED KITS AND SOLUTIONS
          </div>
        </div>
      </Link>
      {/* <div className="relative w-48 h-48 max-md:w-32 max-md:h-32 max-md:text-7xl text-9xl max-2xl:text-[112px] max-2xl:w-[170px] max-xl:text-[90px] max-2xl:h-[170px] max-xl:w-36 max-xl:h-36 font-medium max-xl:-mr-8 text-[#CAC4CE]">
        06
        <div className="font-semibold text-lg max-md:text-base text-white bg-lightBlue w-48 h-48 max-md:w-32 max-md:h-32 max-md:top-[40px] max-2xl:w-[170px] max-2xl:h-[170px] max-xl:w-36 max-xl:h-36 rounded-full flex items-center justify-center text-center absolute top-[70px] max-2xl:top-[55px] max-xl:top-[48px] -left-2 max-md:-left-3 right-0 shadow-lg shadow-darkBlue">
          POC
        </div>
      </div> */}
    </div>
  );
};

export default Products;
