import React, { useEffect, useState } from "react";
import bgCircleBlue from "../assets/symbols/daire---mavi.png";
import symbl from "../assets/symbols/mavi-çizgi.png";
import imagePcc from "../assets/symbols/pcc.png";
import imageMed from "../assets/symbols/medgenetics-logo.png";
import { IoIosMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { TfiWorld } from "react-icons/tfi";
import Footer from "../components/footer/Footer";

const Partners = () => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await fetch(
          "https://cms.diagnotech.com.tr/Api/GetPartners"
        );
        const data = await response.json();
        setPartners(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPartners();
  }, []);

 //console.log(partners);

  const parseHTMLContent = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return <div dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }} />;
  };

  return (
    <div className="max-md:overflow-hidden select-none">
      <div
        className="pb-56 max-2xl:pb-40 max-md:pb-10 max-xl:pb-28"
        style={{
          backgroundImage: `linear-gradient(rgba(247,244,244,0.85), rgba(247,244,244,0.85)), url(${bgCircleBlue})`,
          backgroundPosition: "138% 150%",
          backgroundSize: "50% auto",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex flex-col items-center px-28 max-md:px-5">
          <div className="text-9xl max-2xl:text-8xl max-xl:text-7xl max-md:text-5xl font-bold text-[#199BBC] text-center tracking-[0.020em]">
            PARTNERS
          </div>
          <div className="w-1/3 max-md:w-full scale-y-[1.6] max-md:scale-y-[1] max-2xl:scale-y-[1.4] -mt-20 max-md:-mt-[100px]">
            <img src={symbl} alt="" className="w-full" />
          </div>
          {partners.map((partner, index) => (
            <div
              key={index}
              className="flex items-center max-md:flex-col bg-[#199bbc] text-white mt-36 max-2xl:-mt-28 max-xl:-mt-24 max-md:-mt-32 rounded-2xl shadow-md"
            >
              <div className="w-[25%] max-md:w-[90%] py-12 max-2xl:py-16 pl-24 max-xl:pl-12 pr-12 max-xl:my-5 max-md:py-0">
                <img
                  src={partner.Image}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-[70%] max-md:w-[92%] flex items-stretch max-md:shadow-lg max-md:shadow-gray-400">
                <div className="bg-white w-[2px] mr-16 max-xl:mr-5 max-md:hidden" />
                <div className="flex flex-col text-base font-medium">
                  <div className="pr-10 max-md:pr-0 max-xl:pr-3 mb-10 max-xl:mb-8 max-md:mb-4">
                    {parseHTMLContent(partner.Content)}
                  </div>
                  <div className="flex flex-col gap-2 text-sm max-md:pb-4">
                    <div className="flex gap-2 items-center">
                      <IoIosMail size={20} color="#ffffff" />
                      {partner.Email}
                    </div>
                    <div className="flex gap-2 items-center">
                      <FaPhoneAlt size={17} color="#ffffff" />
                      {partner.Tel}
                    </div>
                    <div className="flex gap-2 items-center">
                      <TfiWorld size={17} color="#ffffff" />
                      {partner.Address}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Partners;
