import React, { useEffect, useState } from "react";
import circle from "../assets/symbols/daire---mavi.png";
import ProductItem from "../components/Product-Solutions/ProductItem";

import Footer from "../components/footer/Footer";
import { useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
// data's
import clinicalData from "../data/clinical";
import animalHealthData from "../data/animalHealth";
import dnaSeqData from "../data/dnaSeq";
import extractionKitData from "../data/extractionKit";
import foodData from "../data/food";
import lifestyleData from "../data/lifestyle";
import ngsProductsData from "../data/ngsProducts";
import pocData from "../data/poc";
import rawMaterialData from "../data/rawMaterial";
import rnaSeqData from "../data/rnaSeq";

const ProductSolutions = () => {
  const { productType } = useParams();

  ////console.log(productType);

  const [Products, setProducts] = useState([]);
  const [dataToUse, setDataToUse] = useState([]);
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `https://cms.diagnotech.com.tr/Api/GetProducts?url=${productType}`
        );
        const data = await response.json();
        setProducts(data);
        setDataToUse(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProducts();
  }, [productType]);


  const getTitleFromParam = (param) => {
    switch (param) {
      case "clinical":
        return "Clinical";
      case "lifestyle-wellness":
        return "Lifestyle / Wellness";
      case "food":
        return "Food";
      case "animal-health":
        return "Animal Health";
      case "extractions-kits":
        return "Extractions Kits";
      case "ngs-services":
        return "Ngs Services";
      case "dna-seq-bundle":
        return "Dna-Seq Bundle";
      case "rna-seq-bundle":
        return "Rna-Seq Bundle";
      case "raw-materials":
        return "Raw Materials";
      case "poc":
        return "Poc";
      default:
        return "";
    }
  };

  const title = getTitleFromParam(productType);

  const mainCategoryForTitle = (title) => {
    if (
      title === "Clinical" ||
      title === "Lifestyle / Wellness" ||
      title === "Food" ||
      title === "Animal Health"
    ) {
      return "Diagnostics & Clinical";
    }
    if (title === "Dna-Seq Bundle" || title === "Rna-Seq Bundle") {
      return "NGS PRODUCTS";
    }
    if (title === "Extractions Kits") {
      return "Extractions Kits";
    }
    if (title === "Raw Materials") {
      return "Raw Materials";
    }
    if (title === "Poc") {
      return "Poc";
    }
    if (title === "Ngs Services") {
      return "Ngs Services";
    }
    // Diğer başlıklar için benzer kontroller ekleyebilirsiniz
    return "";
  };

  const mainCategory = mainCategoryForTitle(title);

  const getDataFromTitle = (title) => {
    switch (title) {
      case "Clinical":
        return clinicalData;
      case "Lifestyle / Wellness":
        return lifestyleData;
      case "Food":
        return foodData;
      case "Animal Health":
        return animalHealthData;
      case "Extractions Kits":
        return extractionKitData;
      case "Ngs Services":
        return ngsProductsData; // Burada doğru datayı döndürdüğümüzden emin olmalıyız
      case "Dna-Seq Bundle":
        return dnaSeqData;
      case "Rna-Seq Bundle":
        return rnaSeqData;
      case "Raw Materials":
        return rawMaterialData;
      case "Poc":
        return pocData;
      default:
        return []; // Varsayılan olarak boş bir dizi döndürdük
    }
  };
 //console.log("data");
 //console.log(dataToUse);
  // pagination

  // Setup state for pagination
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 15;

  // Calculate the items to be displayed on the current page
  const indexOfLastItem = activePage * itemsPerPage;

  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
 //console.log(indexOfFirstItem);
 //console.log(indexOfLastItem);
  const currentItems = dataToUse.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Pencere yeniden boyutlandırıldığında bu işlev çağrılır
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Component yüklendiğinde bir event listener ekleyin ve window boyutunu kontrol edin
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Component yüklenirken ilk kontrolü yapmak için çağırın
    // Component kaldırıldığında event listener'ı temizleyin
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="max-md:overflow-hidden">
      <div className="relative pt-10" style={{ minWidth: "max-content" }}>
        <div className="absolute top-5 -left-20 max-md:-left-16">
          <img src={circle} alt="" className="w-40 max-md:w-32" />
        </div>
        <div className="pl-[calc(5rem+1rem)] max-md:pl-20 pt-4 text-[#199bbc] text-4xl max-md:text-2xl">
          <div className="font-semibold">{mainCategory}</div>
          <div className="font-light">{title}</div>
        </div>
      </div>
      <div className="px-20 max-md:px-4 max-md:pt-11 pt-28 pb-10 max-md:pb-4 grid grid-cols-5 max-md:grid-cols-2 gap-y-12 max-md:gap-y-6 gap-x-6 max-md:gap-x-4">
        {currentItems.map((item, index) => (
          <div key={item.ProductId} className="flex flex-col h-full">
            <ProductItem
              image={item.ProductImage}
              title={item.ProductName}
              urlx={item.ProductId}
              // number={item.ProductId}
            />
          </div>
        ))}
      </div>

      <div className="paginate-container flex justify-center items-center my-10 max-md:my-5">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={dataToUse.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
          prevPageText="<"
          nextPageText=">"
          firstPageText={windowWidth > 768 ? "First Page" : "<<"}
          lastPageText={windowWidth > 768 ? "Last Page" : ">>"}
        />
      </div>
      <Footer />
    </div>
  );
};

export default ProductSolutions;
