import React, { useEffect, useState } from "react";
import "../../style/footer.css";
import bgCircle from "../../assets/symbols/b267c3effa5f12dfa347e2aedba4ef4d.png";
import logo from "../../assets/symbols/beyaz.png";
import { AiOutlineInstagram } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";
import { IoIosMail } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";

const Footer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Pencere yeniden boyutlandırıldığında bu işlev çağrılır.
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Component yüklendiğinde bir event listener ekleyin.
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Component kaldırıldığında event listener'ı temizleyin.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Pencerenin genişliğine bağlı olarak uygulanacak stil.
  const backgroundStyle = {
    backgroundImage: windowWidth < 768 ? `none` : `url(${bgCircle})`,
    backgroundPosition: windowWidth < 768 ? "inherit" : "-15000px center",
    //  backgroundSize: windowWidth < 768 ? "50%" : "initial",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div
      className="xl:pl-20 flex max-md:flex-col w-full pt-8 h-[40vh] max-md:h-auto max-xl:h-[33vh] max-xl:pt-8 max-md:pt-4 max-xl:pb-2 bg-lightBlue bg-opacity-layer text-white overflow-hidden"
      style={backgroundStyle}
    >
      <div className="w-[30%] max-md:w-full flex flex-col justify-start items-start px-10 max-md:px-5">
        <img
          src={logo}
          alt=""
          className="w-72 h-auto -mt-12 max-xl:-mt-12 max-md:-mt-16 max-md:ml-5 max-md:mb-3"
        />
        <div className="text-lg max-xl:text-base -mt-10 max-xl:-mt-8 max-md:-mt-14 max-xl:px-4 max-md:px-0 max-md:text-center xl:pl-5">
          © 2023 Diagnotech All rights reserved.
        </div>
      </div>
      <div className="w-2/3 max-md:w-full flex flex-col items-center justify-between max-md:justify-center">
        <div className="flex max-md:flex-col items-center justify-center w-full px-10 max-xl:px-4">
          <div className="flex-1 max-md:flex-auto max-md:w-full max-md:mx-auto max-md:mt-2 xl:-mt-9">
            <div className="text-3xl max-md:text-2xl font-medium max-md:text-center">
              Follow Us
            </div>
            <div className="w-2/3 max-md:w-full text-sm max-md:text-center">
              Stay up-to-date with our social media accounts for a journey in
              diagnostics!
            </div>
            <div className="flex items-center max-md:justify-center gap-3 mt-10 max-md:mt-1">
              <Link
                target="_blank"
                to="https://www.linkedin.com/company/diagnotech-biotechnology-and-life-science-inc/"
                className="z-50"
              >
                <BsLinkedin size={24} color="#ffffff" />
              </Link>
            </div>
          </div>
          <div className="flex-1 max-md:flex-auto max-md:w-full max-md:mx-auto max-md:mt-2">
            <div className="text-3xl max-md:text-2xl font-medium max-md:text-center">
              Contact Us
            </div>
            <div className="w-2/3 max-md:w-full text-sm max-md:text-center">
              Diagnotech Biyoteknoloji ve <br className="max-md:hidden" /> Yaşam
              Bilimleri A.Ş.
            </div>
            <div className="flex flex-col items-start max-md:justify-center max-md:w-full gap-3 max-md:gap-1 mt-8 max-md:mt-1">
              <div className="flex gap-2 items-center max-md:gap-1 -ml-2 max-md:ml-[50%] max-md:-translate-x-1/2 max-md:w-full">
                <MdLocationOn
                  size={26}
                  color="#ffffff"
                  className="hidden md:block"
                />
                <div className="text-sm max-md:text-center max-md:w-full">
                  Atatürk mahallesi, Kamilbey sokak No:14/1
                  <br className="md:hidden" /> Ataşehir-İSTANBUL TÜRKİYE
                </div>
              </div>
              <div className="flex gap-2 max-md:gap-1 items-center max-md:w-full -ml-1 max-md:ml-[70%] max-md:-translate-x-1/2">
                <IoIosMail size={25} color="#ffffff" />
                <a
                  href="mailto:info@diagnotech.com.tr"
                  className="text-sm max-md:text-sm z-50"
                >
                  info@diagnotech.com.tr
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="h-1 max-md:h-[2px] bg-white w-full max-md:mt-1" />
        <div className="flex gap-2 w-full max-md:px-5 max-md:justify-between text-sm max-md:text-xs">
          <div className="flex items-center max-md:hidden">
            <span>Edit Cookie Settings</span>
          </div>
          <div className="flex items-center gap-[2px]">
            <span className="text-2xl max-md:hidden">•</span>
            <span>Hotline</span>
          </div>
          <div className="flex items-center gap-[2px]">
            <span className="text-2xl max-md:text-lg">•</span>
            <span>Legal Information</span>
          </div>
          <div className="flex items-center gap-[2px]">
            <span className="text-2xl max-md:text-lg">•</span>
            <span>Disclaimer</span>
          </div>
          <div className="flex items-center gap-[2px]">
            <span className="text-2xl max-md:text-lg">•</span>
            <span>Contact</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
