import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import circle from "../assets/symbols/daire---mavi.png";
import Footer from "../components/footer/Footer";
import { PiFilePdf } from "react-icons/pi";

const ProductDetail = () => {
  const { productId } = useParams();

  const [productDetail, setProductDetail] = useState([]);

  useEffect(() => {
    const fetchProductDetail = async () => {
      try {
        const response = await fetch(
          `https://cms.diagnotech.com.tr/Api/GetProductDetail?productId=${productId}`
        );
        const data = await response.json();
        setProductDetail(data);
        //console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProductDetail();
  }, []);

  console.log(productDetail);

  const parseHTMLContent = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return <div dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }} />;
  };

  return (
    <div className="max-md:overflow-hidden relative">
      <div className=" w-fit max-md:w-full pt-10 max-md:pt-5">
        <img
          src={circle}
          alt=""
          className="absolute w-40 max-md:w-36 top-5 -left-[5.5%] max-md:-left-[20%]"
        />
        <div className="pl-24 mb-12 max-md:pl-14 pr-14 max-md:pr-8 pt-4 text-[#199bbc] text-3xl max-md:text-xl flex max-md:flex-col items-start max-md:items-center">
          <div className="flex-1 max-md:flex-initial">
            <div className="font-semibold ml-1 max-md:pl-6">
              {productDetail.ProductName}
            </div>
            <div className="rounded-3xl mt-16 max-md:mt-20 overflow-hidden w-[100%] max-md:scale-[1.17] max-[500px]:scale-[1.1] max-[600px]:scale-[0.95] max-[700px]:scale-[0.89] h-auto max-md:-ml-1 max-w-[600px]">
              <img
                src={productDetail.ProductImage}
                alt=""
                className="w-full h-full rounded-lg"
              />
            </div>
          </div>

          {((productDetail.Properties && productDetail.Properties.length > 0) ||
            (productDetail.Feature && productDetail.Feature.length > 0)) && (
            <div class="pl-24 max-md:pl-4 pr-14 max-md:pr-8 pt-4 text-[#199bbc] text-3xl max-md:text-xl flex max-md:flex-col items-start max-md:items-center">
              <div class="w-full h-full bg-[#e8e7e7] px-6 py-10 max-md:mt-5 rounded-xl text-lg max-md:text-sm text-black">
                {productDetail.Content === "<p><br></p>" ? (
                  ""
                ) : (
                  <div className="-mt-4 mb-4 text-[15px]">
                    {parseHTMLContent(productDetail.Content)}
                  </div>
                )}
                {productDetail.Feature && productDetail.Feature.length > 0 && (
                  <table className="w-full table-auto mb-4">
                    <tbody>
                      {productDetail.Feature.map((item, index) => (
                        <tr key={index} className="text-center">
                          <td className="font-extralight text-base max-md:text-sm tableColor tableHead">
                            {item.Key}
                          </td>
                          <td className="font-extralight text-base max-md:text-sm tableColor">
                            {item.Value}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                {productDetail.Properties &&
                  productDetail.Properties.length > 0 && (
                    <table className="w-full table-auto">
                      <thead>
                        <tr class="text-center">
                          <th class="font-semibold max-md:font-medium tableColor tableHead">
                            Product
                          </th>
                          <th class="font-semibold max-md:font-medium tableColor tableHead">
                            Category No
                          </th>
                          <th class="font-semibold max-md:font-medium tableColor tableHead">
                            Product Size
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {productDetail.Properties.map((property, index) => (
                          <tr key={index} className="text-center">
                            <td className="font-extralight text-base max-md:text-sm tableColor">
                              {property.Product}
                            </td>
                            <td className="font-extralight text-base max-md:text-sm tableColor">
                              {property.CategoryNo}
                            </td>
                            <td className="font-extralight text-base max-md:text-sm tableColor">
                              {property.Size}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                {productDetail.Pdf1 === null ? (
                  ""
                ) : (
                  <div className="flex flex-col my-1 mt-3 gap-1">
                    <a
                      href={
                        "https://cms.diagnotech.com.tr/images/Uploads/" +
                        productDetail.Pdf1
                      }
                      target="_blank"
                      download
                      className="flex items-center gap-1 hover:scale-105 w-fit duration-300"
                    >
                      <PiFilePdf size={22} />
                      <div className="text-lg font-medium text-cyan-500">
                        {productDetail.Pdf1.split("_")
                          .slice(1)
                          .join("_")
                          .replace(/-/g, " ")
                          .replace(/\b\w/g, (letter) => letter.toUpperCase())}
                        <span className="text-sm">(Download PDF)</span>
                      </div>
                    </a>
                  </div>
                )}
                {productDetail.Pdf2 === null ? (
                  ""
                ) : (
                  <div className="flex flex-col my-1 mt-1 gap-1">
                    <a
                      href={
                        "https://cms.diagnotech.com.tr/images/Uploads/" +
                        productDetail.Pdf2
                      }
                      target="_blank"
                      download
                      className="flex items-center gap-1 hover:scale-105 w-fit duration-300"
                    >
                      <PiFilePdf size={22} />
                      <div className="text-lg font-medium text-cyan-500">
                        {productDetail.Pdf2.split("_")
                          .slice(1)
                          .join("_")
                          .replace(/-/g, " ")
                          .replace(/\b\w/g, (letter) => letter.toUpperCase())}
                        <span className="text-sm">(Download PDF)</span>
                      </div>
                    </a>
                  </div>
                )}
                {productDetail.Pdf3 === null ? (
                  ""
                ) : (
                  <div className="flex flex-col my-1 mt-1 gap-1">
                    <a
                      href={
                        "https://cms.diagnotech.com.tr/images/Uploads/" +
                        productDetail.Pdf3
                      }
                      target="_blank"
                      download
                      className="flex items-center gap-1 hover:scale-105 w-fit duration-300"
                    >
                      <PiFilePdf size={22} />
                      <div className="text-lg font-medium text-cyan-500">
                        {productDetail.Pdf3.split("_")
                          .slice(1)
                          .join("_")
                          .replace(/-/g, " ")
                          .replace(/\b\w/g, (letter) => letter.toUpperCase())}
                        <span className="text-sm">(Download PDF)</span>
                      </div>
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
