import React from "react";
import Banner from "../components/home-page/Banner";
import AboutDiagnotech from "../components/home-page/AboutDiagnotech";
import Products from "../components/home-page/Products";
import Diagnosis from "../components/home-page/Diagnosis";
import VideoSection from "../components/home-page/VideoSection";
import MediaCenter from "../components/home-page/MediaCenter";
import ContactUs from "../components/home-page/ContactUs";
import Footer from "../components/footer/Footer";

const Home = () => {
  return (
    <div className="max-md:overflow-hidden">
      <Banner />
      <AboutDiagnotech />
      <div className="max-md:hidden text-7xl max-xl:text-6xl max-md:text-5xl font-bold text-lightBlue text-center my-20 max-xl:my-16">
        PRODUCTS
      </div>
      <div className="hidden max-md:block mt-10 max-md:my-5 text-6xl max-xl:text-5xl max-md:text-3xl text-lightBlue font-medium text-center">
        One company, multiple solutions
      </div>
      <Products />
      <Diagnosis />
      <VideoSection />
      <div>
        <MediaCenter />
        <div className="bg-[#D8D8D8] h-[3px] mt-24 mx-20 max-xl:mx-12 max-md:hidden" />
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
