import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { FaAngleRight, FaMapMarkerAlt, FaPhoneSquareAlt } from "react-icons/fa";
import ReactDOMServer from "react-dom/server";
import { TbWorldWww } from "react-icons/tb";
import { IoIosMail } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import { GiConfirmed } from "react-icons/gi";

const Map = ({ countries }) => {
  const [activeCountry, setActiveCountry] = useState(null);

  // Özel ikon oluştur
  const icon = new L.divIcon({
    className: "custom-icon",
    html: ReactDOMServer.renderToString(
      <FaMapMarkerAlt size={25} color="red" />
    ),
  });

  const handleMarkerClick = (country) => {
    // Önce activeCountry'yi null yap
    setActiveCountry(null);

    // Bir sonraki event loop'ta country'yi set et
    setTimeout(() => {
      setActiveCountry(country);
    }, 0);
  };

  return (
    <MapContainer
      center={[38.4578125, 27.3890625]}
      //   worldCopyJump={true}
      maxBounds={[
        [-90, -180],
        [90, 180],
      ]} // Dünyanın sınırları
      maxBoundsViscosity={1}
      zoom={4}
      className="h-[500px]"
      
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {countries.map((country) => (
        <Marker
          key={country.id}
          position={country.position}
          icon={icon}
          eventHandlers={{
            click: () => handleMarkerClick(country),
          }}
        />
      ))}
      {activeCountry && (
        <Popup
          position={activeCountry.position}
          onClose={() => {
            setActiveCountry(null);
          }}
        >
          <div>
            {/* <p className="font-bold text-lightBlue">{activeCountry.country}</p> */}
            <p className="flex items-center gap-1 text-lightBlue font-medium pl-1 text-sm">
              {activeCountry.company}
            </p>
            <a
              style={{ color: "black" }}
              className="flex items-center gap-1"
              href={activeCountry.web}
              //   target="_blank"
            >
              <TbWorldWww size={18} className="text-lightBlue" />
              {activeCountry.web}
            </a>
            <p className="flex items-center gap-1">
              <FaPhoneSquareAlt size={18} className="text-lightBlue" />
              {activeCountry.tel}
            </p>
            <p className="flex items-center gap-1">
              <IoIosMail size={18} className="text-lightBlue" />
              {activeCountry.mail}
            </p>
            <p className="flex items-center gap-1">
              <MdLocationOn size={18} className="text-lightBlue" />
              {activeCountry.adress}
            </p>
          </div>
        </Popup>
      )}
    </MapContainer>
  );
};

export default Map;
