import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import img1 from "../assets/images/1.haber.jpeg";
// import img2 from "../assets/images/2.haber.png";
// import img3 from "../assets/images/3.haber.jpg";
// import img4 from "../assets/images/4.haber.png";
// import img5 from "../assets/images/5.haber.jpg";
import Footer from "../components/footer/Footer";
import symbl from "../assets/symbols/mavi-çizgi.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import bgCircleBlue from "../assets/symbols/daire---mavi.png";

const NewDetails = () => {
  const { id } = useParams();

  const [newsDetails, setNewsDetail] = useState([]);
  const [mediaItems, setMediaItem] = useState([]);

  useEffect(() => {
    const fetchNewsDetails = async () => {
      try {
        const response = await fetch(
          `https://cms.diagnotech.com.tr/Api/GetNewsDetail?newsId=${id}`
        );
        const data = await response.json();
        setNewsDetail(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchNewsDetails();
  }, []);

  useEffect(() => {
    const fetchMediaItem = async () => {
      try {
        const response = await fetch(
          "https://cms.diagnotech.com.tr/Api/GetMediaCenters"
        );
        const data = await response.json();
        
        // Sadece ilk üç öğeyi al
        const firstThreeItems = data.slice(0, 3);
        
        setMediaItem(firstThreeItems);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchMediaItem();
  }, []);

  const parseHTMLContent = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return (
      <div dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }} />
    );
  };


//   const newsItems = [
//     {
//       id: "item1",
//       image: img1,
//       title: "WE WERE IN The BioTürkiye",
//       text: "We, as Diagnotech, attended the Bio Turkey 2023 conference on September 28-30. Our General Manager, Dr. Onur Bilenoğlu, delivered a speech on Establishing the Supply Chain in the Development of Biotechnological Products, and our Project Manager, Ezgi Kırmızıgül, gave a speech on the Potential for Biotechnology Companies to Attain Unicorn Status.",
//       date: "28.09.2023",
//     },
//     {
//       id: "item2",
//       image: img2,
//       title: "WE WERE IN THE FUTUREHEALTHCARE",
//       text: "As Diagnotech, we participated in The FutureHealthcare 2023 conference on September 27th. Our General Manager, Dr. Onur Bilenoğlu, delivered a speech on innovative initiatives in future health panels.",
//       date: "27.09.2023",
//     },
//     {
//       id: "item3",
//       image: img3,
//       title: "",
//       text: "As Diagnotech Biotechnology, we would like to proudly share that we achieved the first return of the work we started in May 2022 in the first half of 2023.Our Project titled ‘ Development of Diagnostic Kit Using Molecular Beacon and 2D Multiplex RT-PCR Technique’ with the application number ‘7220793’, which we applied to the 2nd Call of TÜBİTAK 1507 SME R&D Startup Program 2022, was entitled to be supported.With this good return, we will continue our R&D studies in the field of Life Sciences without slowing down.",
//       date: "01.01.2023",
//     },
//     {
//       id: "item4",
//       image: img4,
//       title: "",
//       text: "The Diagnotech family has started its training sessions in the field of Life Sciences. In this context, we have successfully completed the training sessions given to the Junior Biologist Community of the Turkish Biologists Association on October 21-22 and November 1, 2022. We would like to express our gratitude to all participants and our team members.",
//       date: "01.11.2022",
//     },
//     {
//       id: "item5",
//       image: img5,
//       title:
//         "As Diagnotech, we participated in The FutureHealthcare 2023 conference on September 27th",
//       text: `As Diagnotech, on Thursday announced a collaboration with Diagnovital to offer an evolving portfolio of PCR-based assays for the
//  identification of SARS-CoV-2 mutations and variants.Erlangen, Germany-based Siemens said a portfolio of 
// Diagnovital kits will complement its FTD SARS-CoV-2 Assay intended for the initial diagnosis of SARS-CoV-2.
// When the FTD SARS-CoV-2 Assay detects a positive sample, the residual nucleic acid extracted from the sample can be reflex tested by a 
// Diagnovital kit or a combination of the kits to detect whether the sample harbors a mutation or variant of concern.
// The Diagnovital portfolio, as distributed by Siemens, will be offered for research use only internationally but will not be 
// offered initially in the US. Financial and other terms of the agreement were not disclosed.
// Siemens said that testing will be highly adaptable given the certainty that further mutations and variants will emerge and uncertainty whether the variants will become variants of concernIt said that with the collaboration, laboratories can select specific assays to create a testing scheme based on mutations prevalent in a region,using the single-mutation formatted assays that are part of the Diagnovital portfolio.`,
//       date: "01.11.2022",
//     },
//   ];

   const currentItem = newsDetails ;

  return (
    <>
      <div className="flex flex-col">
      
        <div className="bg-[#4bb7cf] h-[60vh] max-xl:h-[50vh] max-md:h-[38vh] pt-9 max-2xl:pt-5 max-md:pt-6 relative">
          <div
            style={{
              display: !currentItem.Title ? "none" : "block",
            }}
            className="w-full text-3xl max-xl:text-2xl max-md:text-lg font-medium text-center px-56 max-2xl:px-40 max-xl:px-32 max-md:px-2 pt-7 max-2xl:pt-5 max-md:pt-2 pb-4 max-md:pb-2 bg-white"
          >
            {currentItem.Title}
          </div>
          <div className="text-center py-10 max-xl:py-6 max-2xl:py-8 max-md:py-2 text-white text-3xl max-md:text-[22px]">
            {currentItem.Date}
          </div>
          <div
            className={`absolute w-[80%] max-md:rounded-lg max-md:overflow-hidden max-md:w-[90%] ${
              currentItem.Title ? "max-md:top-[70%] " : "max-md:top-[50%]"
            } -bottom-[200px] max-md:-bottom-[100px] left-1/2 -translate-x-1/2 h-[400px] max-md:h-[150px] shadow-lg shadow-gray-300`}
          >
            <img
              src={currentItem.Image}
              alt="News"
              className="w-full h-full object-cover"
              // style={{ objectPosition: "0% 45%" }}
            />
          </div>
        </div>
      </div>
      <div className="text-lg max-md:text-base mt-64 max-md:mt-32 w-4/5 max-md:w-[94%] max-2xl:w-[82%] mx-auto max-md:text-center">
      {parseHTMLContent(currentItem.Content)}
        {/* {currentItem.Content} */}
      </div>
      <div
        className="pb-20 max-2xl:pb-10 max-xl:pb-5 max-md:mt-40"
        style={{
          backgroundImage: `linear-gradient(rgba(247,244,244,0.94), rgba(247,244,244,0.94)), url(${bgCircleBlue})`,
          backgroundPosition: "190% -90%",
          backgroundSize: "75% auto",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-1/3 max-md:w-full scale-110 max-md:scale-100 -mt-40 max-2xl:-mt-32 max-xl:-mt-24 max-md:-mt-64 mx-auto">
          <img src={symbl} alt="" className="w-full" />
        </div>
        <div className="flex justify-center items-stretch max-md:items-center max-md:flex-col gap-10 max-md:gap-6 -mt-48 max-2xl:-mt-40 max-xl:-mt-32 max-md:-mt-36 px-48 max-xl:px-20 max-md:px-0 ml-10 max-md:ml-1">
        {mediaItems.map((mediaItem, index) => (
          <div className="flex flex-1 max-md:flex-auto flex-col justify-between gap-9 max-md:gap-5 max-md:w-full max-md:items-center">
            <img
              src={mediaItem.SmallImage}
              alt=""
              className="w-72 h-40 max-xl:w-[278px] max-md:w-[90%] max-xl:h-[150px] rounded-2xl shadow-lg shadow-gray-400 object-cover"
            />
            <div className="text-2xl max-xl:text-[22px] max-md:text-xl max-xl:pl-2 max-md:pl-0">
              {mediaItem.Date}
            </div>
            <div className="text-lightBlue text-xl max-md:text-lg font-semibold max-w-[18rem] max-md:w-full max-xl:pl-2 max-md:pl-0 max-md:text-center">
              {mediaItem.Title}
            </div>
            <div className="w-3/4 max-xl:w-[95%] max-xl:pl-2 max-md:pl-0 max-md:w-[89%]">
              <div className="flex justify-between items-center text-lightBlue">
                <Link
                  to="/news"
                  className="text-lg max-md:text-base cursor-pointer hover:scale-95 duration-300"
                >
                  Read More
                </Link>
                <div>
                  <FontAwesomeIcon
                    icon={faArrowRightLong}
                    className="max-md:text-xl text-[22px]"
                  />
                </div>
              </div>
              <div className="h-[2px] max-md:h-[1.5px] bg-lightBlue" />
            </div>
          </div>
          ))}
        
        </div>
      </div>
      <Footer />
      {/* <div>
        {currentItem ? (
          <>
            <img src={currentItem.image} alt="News" />
            <p>{currentItem.text}</p>
          </>
        ) : (
          <p>Öğe bulunamadı.</p>
        )}
      </div> */}
    </>
  );
};

export default NewDetails;
