import React, { useEffect, useState } from "react";
import symbl from "../../assets/symbols/mavi-çizgi.png";
// import img1 from "../../assets/images/1.haber.jpeg";
// import img2 from "../../assets/images/2.haber.png";
// import img3 from "../../assets/images/3.haber.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import bgCircleBlue from "../../assets/symbols/daire---mavi.png";
import { Link } from "react-router-dom";

const MediaCenter = () => {
  const [mediaCenters, setMediaCenter] = useState([]);

  useEffect(() => {
    const fetchMediaCenter = async () => {
      try {
        const response = await fetch(
          "https://cms.diagnotech.com.tr/Api/GetMediaCenters"
        );
        const data = await response.json();
        setMediaCenter(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchMediaCenter();
  }, []);

  //console.log(mediaCenters);

  return (
    <div
      className="flex flex-col items-center justify-center max-md:hidden"
      style={{
        backgroundImage: `linear-gradient(rgba(247,244,244,0.87), rgba(247,244,244,0.87)), url(${bgCircleBlue})`,
        backgroundPosition: "230% 100%",
        backgroundSize: "70% auto",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div>
        <div className="text-9xl max-xl:text-8xl font-bold text-lightBlue text-center mt-16 max-xl:mt-14">
          MEDIA
        </div>
        <div className="text-center text-6xl max-xl:text-5xl mt-3">CENTER</div>
      </div>
      <div className="text-2xl mt-14 max-2xl:mt-10">
        Press releases, news, projects and more.
      </div>
      <div className="w-1/3 scale-110 -mt-40 max-2xl:-mt-28 max-xl:-mt-24">
        <img src={symbl} alt="" className="w-full" />
      </div>
      <div className="flex justify-between -mt-48 max-2xl:-mt-40 max-xl:-mt-32 w-full px-40 max-2xl:px-36 max-xl:px-24">
        {mediaCenters.map((mediaCenter) => (
          <div
            key={mediaCenter.id}
            className="flex flex-1 flex-col items-start justify-between gap-9"
          >
            <Link
              to={`/news/details/${mediaCenter?.Id}`}
              className="text-lg cursor-pointer hover:scale-95 duration-300"
            >
              <img
                src={mediaCenter.SmallImage}
                alt=""
                className="w-80 h-48 max-2xl:w-72 max-2xl:h-40 max-xl:w-64 max-xl:h-40 rounded-2xl shadow-lg shadow-gray-400 object-cover"
              />
            </Link>
            <div className="text-2xl max-2xl:text-xl max-xl:text-[24px] text-lightBlue">
              {mediaCenter.Date}
            </div>
            <Link
              to={`/news/details/${mediaCenter?.Id}`}
              className="text-lg cursor-pointer hover:scale-95 duration-300"
            >
              <div className="text-lightBlue text-3xl max-2xl:text-2xl font-semibold w-4/5">
                {mediaCenter.Title}
              </div>
            </Link>
            <div className="w-3/4">
              <div className="flex justify-between items-center text-lightBlue">
                <Link
                  to={`/news/details/${mediaCenter?.Id}`}
                  className="text-lg cursor-pointer hover:scale-95 duration-300"
                >
                  Read More
                </Link>
                <div>
                  <FontAwesomeIcon
                    icon={faArrowRightLong}
                    style={{ fontSize: "22px" }}
                  />
                </div>
              </div>
              <div className="h-[2px] bg-lightBlue" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MediaCenter;
