const productsClinical = [
  {
    id: 1,
    name: "PhoenixDx™ Toxoplasma gondii",
    number: "PCCSKU15312",
    image: 1,
  },
  {
    id: 2,
    name: "PhoenixDx™ Streptococcus agalactiae",
    number: "PCCSKU15070",
    image: 1,
  },
  {
    id: 3,
    name: "PhoenixDx™ Helicobacter pylori",
    number: "PCCSKU15010",
    image: 1,
  },
  {
    id: 4,
    name: "PhoenixDx™ Legionella pneumophila",
    number: "PCCSKU15006",
    image: 1,
  },
  {
    id: 5,
    name: "PhoenixDx™ Listeria monocytogenes",
    number: "PCCSKU15328",
    image: 1,
  },
  {
    id: 6,
    name: "PhoenixDx™ SARS-CoV-2 Multiplex IVD",
    number: "PCCSKU15263",
    image: 1,
  },
  {
    id: 7,
    name: "PhoenixDx™ Cofluenza",
    number: "PCCSKU15265",
    image: 1,
  },
  {
    id: 8,
    name: "PhoenixDx™ Clostridioides difficile 4-Plex",
    number: "PCCSKU15342",
    image: 1,
  },
  {
    id: 9,
    name: "PhoenixDx™ Chlamydia trachomatis",
    number: "PCCSKU15314",
    image: 1,
  },
  {
    id: 10,
    name: "PhoenixDx™ Yersinia enterocolitica",
    number: "PCCSKU15340",
    image: 1,
  },
  {
    id: 11,
    name: "PhoenixDx™ Varizella-Zoster Virus",
    number: "PCCSKU15339",
    image: 1,
  },
  {
    id: 12,
    name: "PhoenixDx™ S. epidermidis",
    number: "PCCSKU15327",
    image: 1,
  },
  {
    id: 13,
    name: "PhoenixDx™ Salmonella spp.",
    number: "PCCSKU15326",
    image: 1,
  },
  {
    id: 14,
    name: "PhoenixDx™ HPV 16/18",
    number: "PCCSKU15306",
    image: 1,
  },
  {
    id: 15,
    name: "PhoenixDx™ Hepatitis B Virus - Quantitative",
    number: "PCCSKU15305",
    image: 1,
  },
  {
    id: 16,
    name: "PhoenixDx™ Hepatitis B Virus",
    number: "PCCSKU15304",
    image: 1,
  },
  {
    id: 17,
    name: "PhoenixDx™ Hepatitis C Virus - Quantitative",
    number: "PCCSKU15303",
    image: 1,
  },
  {
    id: 18,
    name: "PhoenixDx™ Hepatitis C Virus",
    number: "PCCSKU15302",
    image: 1,
  },
  {
    id: 19,
    name: "PhoenixDx™ Mycoplasma Mix",
    number: "PCCSKU15209",
    image: 1,
  },
  {
    id: 20,
    name: "PhoenixLyo MRSA",
    number: "PCCSKU21041",
    image: 1,
  },
  {
    id: 21,
    name: "PhoenixDx™ HCMV",
    number: "PCCSKU15313",
    image: 1,
  },
  {
    id: 22,
    name: "PhoenixDx™ HIV 1/2",
    number: "PCCSKU15307",
    image: 1,
  },
  {
    id: 23,
    name: "Diagnotech SARS-CoV-2 Real Time PCR Kit",
    number: "DBCOV0001",
    image: 1,
  },
  {
    id: 24,
    name: "Diagnotech SARS-CoV-2 Real Time PCR Kit",
    number: "DBCOV0002",
    image: 1,
  },
  {
    id: 25,
    name: "Diagnotech SARS-CoV-2 Real Time PCR Kit",
    number: "DBCOV0003",
    image: 1,
  },
  {
    id: 26,
    name: "Diagnotech SARS-CoV-2 Real Time PCR Kit (Strip)",
    number: "DBCOVS80001",
    image: 1,
  },
  {
    id: 27,
    name: "Diagnotech SARS-CoV-2 Real Time PCR Kit (Strip)",
    number: "DBCOVS80002",
    image: 1,
  },
  {
    id: 28,
    name: "Diagnotech Diagno2Gene Real Time PCR Kit",
    number: "DB2G0001",
    image: 1,
  },
  {
    id: 29,
    name: "Diagnotech Diagno2Gene Real Time PCR Kit",
    number: "DB2G0002",
    image: 1,
  },
  {
    id: 30,
    name: "Diagnotech Diagno2Gene Real Time PCR Kit",
    number: "DB2G0003",
    image: 1,
  },
  {
    id: 31,
    name: "Diagnotech INF A-B SARS-CoV-2-RSV Real Time PCR Kit",
    number: "0115100",
    image: 1,
  },
  {
    id: 32,
    name: "Diagnotech INF A-B SARS-CoV-2-RSV Real Time PCR Kit",
    number: "0115050",
    image: 1,
  },
  {
    id: 33,
    name: "Diagnotech INF A-B SARS-CoV-2-RSV Real Time PCR Kit",
    number: "0115025",
    image: 1,
  },
  {
    id: 34,
    name: "Diagnotech Resplex INF A, INF B, RSV, Sars CoV-2 RT  qPCR Detection Kit",
    number: "0149100",
    image: 1,
  },
  {
    id: 35,
    name: "Diagnotech Resplex INF A, INF B, RSV, Sars CoV-2 RT qPCR Detection Kit",
    number: "0149050",
    image: 1,
  },
  {
    id: 36,
    name: "Diagnotech Resplex INF A, INF B, RSV, Sars CoV-2 RT  qPCR Detection Kit",
    number: "0149025",
    image: 1,
  },
  {
    id: 37,
    name: "Diagnotech Orthopox Virus Nucleic Acid Detection Kit",
    number: "0141100",
    image: 1,
  },
  {
    id: 38,
    name: "Diagnotech Orthopox Virus Nucleic Acid Detection Kit",
    number: "0141050",
    image: 1,
  },
  {
    id: 39,
    name: "Diagnotech Orthopox Virus Nucleic Acid Detection Kit",
    number: "0141025",
    image: 1,
  },
  {
    id: 40,
    name: "Diagnotech Monkeypox Virus Nucleic Acid Detection Kit",
    number: "DMP0001",
    image: 1,
  },
  {
    id: 41,
    name: "Diagnotech Monkeypox Virus Nucleic Acid Detection Kit",
    number: "DMP0002",
    image: 1,
  },
  {
    id: 42,
    name: "Diagnotech Monkeypox Virus Nucleic Acid Detection Kit",
    number: "DMP0003",
    image: 1,
  },
  {
    id: 43,
    name: "Diagnovital HBV Real Time PCR Kit",
    number: "0104100",
    image: 2,
  },
  {
    id: 44,
    name: "Diagnovital HBV Real Time PCR Kit",
    number: "0104050",
    image: 2,
  },
  {
    id: 45,
    name: "Diagnovital HBV Real Time PCR Kit",
    number: "0104025",
    image: 2,
  },
  {
    id: 46,
    name: "Diagnovital HCV Real Time PCR Kit",
    number: "0105100",
    image: 2,
  },
  {
    id: 47,
    name: "DiagnovitalHCV Real Time PCR Kit",
    number: "0105050",
    image: 2,
  },
  {
    id: 48,
    name: "Diagnovital HCV Real Time PCR Kit",
    number: "0105025",
    image: 2,
  },
  {
    id: 49,
    name: "Diagnovital HDV Real Time PCR Kit",
    number: "0106100",
    image: 2,
  },
  {
    id: 50,
    name: "Diagnovital HDV Real Time PCR Kit",
    number: "0106050",
    image: 2,
  },
  {
    id: 51,
    name: "Diagnovital HDV Real Time PCR Kit",
    number: "0106025",
    image: 2,
  },
  {
    id: 52,
    name: "Diagnovital HIV-1 Real Time PCR Kit",
    number: "0107100",
    image: 2,
  },
  {
    id: 53,
    name: "Diagnovital HIV-1 Real Time PCR Kit",
    number: "0107050",
    image: 2,
  },
  {
    id: 54,
    name: "Diagnovital HIV-1 Real Time PCR Kit",
    number: "0107025",
    image: 2,
  },
  {
    id: 55,
    name: "Diagnovital HSV Real Time PCR Kit",
    number: "0114100",
    image: 2,
  },
  {
    id: 56,
    name: "Diagnovital HSV Real Time PCR Kit",
    number: "0114050",
    image: 2,
  },
  {
    id: 57,
    name: "Diagnovital HSV Real Time PCR Kit",
    number: "0114025",
    image: 2,
  },
  {
    id: 58,
    name: "Diagnovital CMV Real Time PCR Kit",
    number: "0102100",
    image: 2,
  },
  {
    id: 59,
    name: "Diagnovital CMV Real Time PCR Kit",
    number: "0102050",
    image: 2,
  },
  {
    id: 60,
    name: "Diagnovital CMV Real Time PCR Kit",
    number: "0102025",
    image: 2,
  },
  {
    id: 61,
    name: "Diagnovital EBV Real Time PCR Kit",
    number: "0103100",
    image: 2,
  },
  {
    id: 62,
    name: "Diagnovital EBV Real Time PCR Kit",
    number: "0103050",
    image: 2,
  },
  {
    id: 63,
    name: "Diagnovital EBV Real Time PCR Kit",
    number: "0103025",
    image: 2,
  },
  {
    id: 64,
    name: "Diagnovital Parvovirus Real Time PCR Kit",
    number: "0109100",
    image: 2,
  },
  {
    id: 65,
    name: "Diagnovital Parvovirus Real Time PCR Kit",
    number: "0109050",
    image: 2,
  },
  {
    id: 66,
    name: "Diagnovital Parvovirus Real Time PCR Kit",
    number: "0109025",
    image: 2,
  },
  {
    id: 67,
    name: "Diagnovital H1N1 Real Time PCR Kit",
    number: "0113100",
    image: 2,
  },
  {
    id: 68,
    name: "Diagnovital H1N1 Real Time PCR Kit",
    number: "0113050",
    image: 2,
  },
  {
    id: 69,
    name: "Diagnovital H1N1 Real Time PCR Kit",
    number: "0113025",
    image: 2,
  },
  {
    id: 70,
    name: "Diagnovital BKV-JCV Real Time PCR Kit",
    number: "0111100",
    image: 2,
  },
  {
    id: 71,
    name: "Diagnovital BKV-JCV Real Time PCR Kit",
    number: "0111050",
    image: 2,
  },
  {
    id: 72,
    name: "Diagnovital BKV-JCV Real Time PCR Kit",
    number: "0111025",
    image: 2,
  },
  {
    id: 73,
    name: "Diagnovital BKV Real Time PCR Kit",
    number: "0101100",
    image: 2,
  },
  {
    id: 74,
    name: "Diagnovital BKV Real Time PCR Kit",
    number: "0101050",
    image: 2,
  },
  {
    id: 75,
    name: "Diagnovital BKV Real Time PCR Kit",
    number: "0101025",
    image: 2,
  },
  {
    id: 76,
    name: "Diagnovital JCV Real Time PCR Kit",
    number: "0108100",
    image: 2,
  },
  {
    id: 77,
    name: "Diagnovital JCV Real Time PCR Kit",
    number: "0108050",
    image: 2,
  },
  {
    id: 78,
    name: "Diagnovital JCV Real Time PCR Kit",
    number: "0108025",
    image: 2,
  },
  {
    id: 79,
    name: "Diagnovital MTBC Real Time PCR Kit",
    number: "0117100",
    image: 2,
  },
  {
    id: 80,
    name: "Diagnovital MTBC Real Time PCR Kit",
    number: "0117050",
    image: 2,
  },
  {
    id: 81,
    name: "Diagnovital MTBC Real Time PCR Kit",
    number: "0117025",
    image: 2,
  },
  {
    id: 82,
    name: "Diagnovital Brucella Real Time PCR Kit",
    number: "0112100",
    image: 2,
  },
  {
    id: 83,
    name: "Diagnovital Brucella Real Time PCR Kit",
    number: "0112050",
    image: 2,
  },
  {
    id: 84,
    name: "Diagnovital Brucella Real Time PCR Kit",
    number: "0112025",
    image: 2,
  },
  {
    id: 85,
    name: "Diagnovital Aspergillus Real Time PCR Kit",
    number: "0110100",
    image: 2,
  },
  {
    id: 86,
    name: "Diagnovital Aspergillus Real Time PCR Kit",
    number: "0110050",
    image: 2,
  },
  {
    id: 87,
    name: "Diagnovital AspergillusReal Time PCR Kit",
    number: "0110025",
    image: 2,
  },
  {
    id: 88,
    name: "Diagnovital VRE Real Time PCR Kit",
    number: "0119100",
    image: 2,
  },
  {
    id: 89,
    name: "Diagnovital VREReal Time PCR Kit",
    number: "0119050",
    image: 2,
  },
  {
    id: 90,
    name: "Diagnovital VRE Real Time PCR Kit",
    number: "0119025",
    image: 2,
  },
  {
    id: 91,
    name: "Diagnovital MRSA Real Time PCR Kit",
    number: "0116100",
    image: 2,
  },
  {
    id: 92,
    name: "Diagnovital MRSA Real Time PCR Kit",
    number: "0116050",
    image: 2,
  },
  {
    id: 93,
    name: "Diagnovital MRSA Real Time PCR Kit",
    number: "0116025",
    image: 2,
  },
  {
    id: 94,
    name: "Diagnovital Prothrombin (G20210A) Real Time PCR Kit",
    number: "0128100",
    image: 2,
  },
  {
    id: 95,
    name: "Diagnovital Prothrombin (G20210A) Real Time PCR Kit",
    number: "0128050",
    image: 2,
  },
  {
    id: 96,
    name: "Diagnovital Prothrombin (G20210A) Real Time PCR Kit",
    number: "0128025",
    image: 2,
  },
  {
    id: 97,
    name: "Diagnovital FV R2 (H1299R) Real Time PCR Kit",
    number: "0121100",
    image: 2,
  },
  {
    id: 98,
    name: "Diagnovital FV R2 (H1299R) Real Time PCR Kit",
    number: "0121050",
    image: 2,
  },
  {
    id: 99,
    name: "Diagnovital FV R2 (H1299R) Real Time PCR Kit",
    number: "0121025",
    image: 2,
  },
  {
    id: 100,
    name: "Diagnovital Factor V Leiden Real Time PCR Kit",
    number: "0120100",
    image: 2,
  },
  {
    id: 101,
    name: "Diagnovital Factor V Leiden Real Time PCR Kit",
    number: "0120050",
    image: 2,
  },
  {
    id: 102,
    name: "Diagnovital Factor V Leiden Real Time PCR Kit",
    number: "0120025",
    image: 2,
  },
  {
    id: 103,
    name: "Diagnovital MTHFR (A1298C) Real Time PCR Kit",
    number: "0125100",
    image: 2,
  },
  {
    id: 104,
    name: "Diagnovital MTHFR (A1298C) Real Time PCR Kit",
    number: "0125050",
    image: 2,
  },
  {
    id: 105,
    name: "Diagnovital MTHFR (A1298C) Real Time PCR Kit",
    number: "0125025",
    image: 2,
  },
  {
    id: 106,
    name: "Diagnovital MTHFR (C677T) Real Time PCR Kit",
    number: "0126100",
    image: 2,
  },
  {
    id: 107,
    name: "Diagnovital MTHFR (C677T) Real Time PCR Kit",
    number: "0126050",
    image: 2,
  },
  {
    id: 108,
    name: "Diagnovital MTHFR (C677T) Real Time PCR Kit",
    number: "0126025",
    image: 2,
  },
  {
    id: 109,
    name: "Diagnovital PAI-1 4G-5G Real Time PCR Kit",
    number: "0127100",
    image: 2,
  },
  {
    id: 110,
    name: "Diagnovital PAI-1 4G-5G Real Time PCR Kit",
    number: "0127050",
    image: 2,
  },
  {
    id: 111,
    name: "Diagnovital PAI-1 4G-5G Real Time PCR Kit",
    number: "0127025",
    image: 2,
  },
  {
    id: 112,
    name: "Diagnovital HLA B27 Real Time PCR Kit",
    number: "0122100",
    image: 2,
  },
  {
    id: 113,
    name: "Diagnovital HLA B27 Real Time PCR Kit",
    number: "0122050",
    image: 2,
  },
  {
    id: 114,
    name: "Diagnovital HLA B27 Real Time PCR Kit",
    number: "0122025",
    image: 2,
  },
  {
    id: 115,
    name: "Diagnovital JAK2 Real Time PCR Kit",
    number: "0124100",
    image: 2,
  },
  {
    id: 116,
    name: "Diagnovital JAK2 Real Time PCR Kit",
    number: "0124050",
    image: 2,
  },
  {
    id: 117,
    name: "Diagnovital JAK2 Real Time PCR Kit",
    number: "0124025",
    image: 2,
  },
  {
    id: 118,
    name: "Diagnovital IL28B Real Time PCR Kit",
    number: "0123100",
    image: 2,
  },
  {
    id: 119,
    name: "Diagnovital IL28B Real Time PCR Kit",
    number: "0123050",
    image: 2,
  },
  {
    id: 120,
    name: "Diagnovital IL28B Real Time PCR Kit",
    number: "0123025",
    image: 2,
  },
  {
    id: 121,
    name: "Diagnotech IL28B Real Time PCR Kit",
    number: "0123025",
    image: 1,
  },
];
export default productsClinical;
