import React, { useEffect, useState } from "react";
import MediaItem from "../components/news/MediaItem";
import bgCircleBlue from "../assets/symbols/daire---mavi.png";
// import img1 from "../assets/images/1.haber.jpeg";
// import img2 from "../assets/images/2.haber.png";
// import img3 from "../assets/images/3.haber.jpg";
// import img4 from "../assets/images/4.haber.png";
// import img5 from "../assets/images/5.haber.jpg";
import Footer from "../components/footer/Footer";

const News = () => {
  const [mediaItems, setMediaItem] = useState([]);

  useEffect(() => {
    const fetchMediaItem = async () => {
      try {
        const response = await fetch(
          "https://cms.diagnotech.com.tr/Api/GetMediaCenters"
        );
        const data = await response.json();
        setMediaItem(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchMediaItem();
  }, []);


  return (
    <>
      <div
        className="max-md:overflow-hidden"
        style={{
          backgroundImage: `linear-gradient(rgba(247,244,244,0.95), rgba(247,244,244,0.95)), url(${bgCircleBlue})`,
          backgroundPosition: "181% 190%",
          backgroundSize: "60% auto",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div>
          <div className="text-9xl max-xl:text-8xl max-md:text-7xl font-bold text-[#fcae3b] text-center mt-5 max-xl:mt-0 tracking-[0.020em]">
            MEDIA
          </div>
          <div className="text-center text-4xl max-md:text-3xl mt-2 max-md:mt-1">
            CENTER
          </div>
        </div>
        <div className="grid grid-cols-3 max-md:grid-cols-1 py-20 max-xl:py-14 max-md:py-5 px-36 max-xl:px-20 max-md:px-5 gap-x-12 max-md:gap-x-0 max-xl:gap-x-10 gap-y-20 max-xl:gap-y-12 max-md:gap-y-8 max-md:w-full">
        {mediaItems.map((mediaItem, index) => (
         
          <MediaItem
            id={mediaItem.Id}
            image={mediaItem.SmallImage}
            text={
              mediaItem.Title
            }
            date={mediaItem.Date}
          />

))}
        
          {/* <MediaItem
            id="item5"
            image={img5}
            text={
              "Siemens Healthineers on Thursday announced a collaboration with Diagnovital..."
            }
            date={"01.11.2022"}
          /> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default News;
