import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import toast, { Toaster } from "react-hot-toast";
import img1 from "../../assets/images/doctor-or-nurse-holding-coronavirus-covid-19-vacci-2022-10-17-01-18-13-utc.jpg";
import img2 from "../../assets/images/hands-of-medical-doctor-with-corona-virus-model-a-2022-10-05-17-35-50-utc.jpg";
import img3 from "../../assets/images/drops-and-bubbles-of-mercury-in-water-dangerous-c-2021-09-02-14-59-37-utc.jpg";
import img4 from "../../assets/images/dna-structure-2021-08-26-18-26-31-utc.jpg";
import img5 from "../../assets/images/microscope-2021-08-30-09-28-42-utc.jpg";

const notifySuccess = () => toast.success("Your message sent.");
const notifyError = () => toast.error("Please fill the form correctly.");

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Form verilerini al
    const formData = new FormData(form.current);

    // Gerekli alanları kontrol et
    const name = formData.get("user_name");
    const email = formData.get("user_email");
    const message = formData.get("message");

    if (
      !name ||
      name.length < 3 ||
      !isValidEmail(email) ||
      !message ||
      message.length < 3
    ) {
      // Hata durumunda bildirim göster
      notifyError();
      return;
    }

    // Eğer gerekli validasyonlar geçildiyse email gönder
    emailjs
      .sendForm(
        "service_onm4tmo",
        "template_tx45htg",
        form.current,
        "PewA3oNi8qBF26kH9"
      )
      .then(
        (result) => {
          ////console.log(result.text);
          ////console.log("Mesaj gönderildi");
          notifySuccess();
          e.target.reset();
        },
        (error) => {
          ////console.log(error.text);
          notifyError();
        }
      );
  };

  // Email formatını kontrol eden yardımcı fonksiyon
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const { name, email, message } = formData;
  //   const mailBody = `Ad Soyad: ${name}\nE-Posta: ${email}\nMesaj: ${message}`;

  //  //console.log("Mail Body:", mailBody);

  // };

  return (
    <div className="flex px-36 max-md:px-8 max-xl:px-14 max-xl:-mb-28 max-md:mb-10 max-md:mt-5">
      <div className="flex-1 max-md:flex-auto">
        <div className="mt-4 max-xl:mt-5 max-md:pr-2">
          <div className="text-4xl font-semibold max-md:text-3xl max-md:text-center">
            Contact Us
          </div>
          <div className="w-full text-base mt-2 max-md:text-center max-md:font-medium">
            Ready to tailor our solutions to your specific requirements? Tell us
            about your unique needs, and let us shape a customized solution for
            you.
          </div>
        </div>
        <div className="py-2 bg-gray-200 w-[96%] flex items-center justify-center rounded-lg shadow-md mt-5">
          <Toaster position="top-right" reverseOrder={false} />
          <form
            ref={form}
            onSubmit={sendEmail}
            className="w-[95%] flex flex-col gap-2"
          >
            <div className="flex">
              <label
                htmlFor="name"
                className="w-1/6 border-l border-t border-b border-gray-300 text-sm flex items-center justify-center px-1 py-[2px] bg-[#049bbc] text-gray-200 rounded-tl-lg rounded-bl-lg leading-tight"
              >
                Name
              </label>
              <input
                name="user_name"
                className="w-full rounded-tr-lg rounded-br-lg border border-gray-300 p-2 text-sm outline-none shadow-sm"
                placeholder="Name Surname"
                type="text"
              />
            </div>
            <div className="flex">
              <label
                htmlFor="email"
                className="w-1/6 border-l border-t border-b border-gray-300 text-sm flex items-center justify-center px-1 py-[2px] bg-[#049bbc] text-gray-200 rounded-tl-lg rounded-bl-lg leading-tight"
              >
                Mail
              </label>
              <input
                name="user_email"
                className="w-full rounded-tr-lg rounded-br-lg border border-gray-300 p-2 text-sm outline-none shadow-sm"
                placeholder="E-Mail"
                type="email"
              />
            </div>
            <div className="flex">
              <textarea
                name="message"
                className="w-full rounded-tr-lg rounded-br-lg border border-gray-300 p-2 text-sm outline-none shadow-sm"
                placeholder="Write your message..."
                rows="8"
              ></textarea>
            </div>
            <div className="flex w-full items-center justify-end">
              <button
                className="w-full inline rounded-lg bg-lightBlue py-1 px-3 text-white select-none border-lightBlue border-[2px] hover:scale-95 duration-300"
                type="submit"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="flex-1 flex max-md:hidden">
        <div className="flex-1 flex flex-col gap-5 max-xl:gap-2 overflow-hidden h-[70vh] max-xl:h-[65vh]">
          <img src={img1} alt="" className="object-cover rounded-b-3xl" />
          <img
            src={img2}
            alt=""
            className="h-[45vh] max-xl:h-[32.4vh] object-cover rounded-3xl"
          />
          <img
            src={img3}
            alt=""
            className="object-cover object-center h-[15%] rounded-t-3xl"
          />
        </div>
        <div className="flex-1 flex flex-col gap-5 max-xl:gap-2 h-[70vh] px-10 max-xl:px-5">
          <img
            src={img4}
            alt=""
            className="h-[45vh] max-xl:h-[35.4vh] object-cover rounded-3xl"
          />
          <img src={img5} alt="" className="object-cover rounded-t-3xl" />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
