import React, { useEffect, useState } from "react";
import bgCircle from "../../assets/symbols/b267c3effa5f12dfa347e2aedba4ef4d.png";
import bgCircleBlue from "../../assets/symbols/daire---mavi.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../style/diagnosis.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper/modules";

import slide1 from "../../assets/images/1632fdc764d761a25efefb835f5050fa.jpg";
import slide2 from "../../assets/images/slide2.jpeg";
import icon1 from "../../assets/symbols/pin.png";
import icon2 from "../../assets/symbols/takvim.png";

const Diagnosis = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slides, setSlides] = useState([]);
  // Pencere yeniden boyutlandırıldığında bu işlev çağrılır.
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Component yüklendiğinde bir event listener ekleyin.
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://cms.diagnotech.com.tr/Api/SummitDays"
        );
        const data = await response.json();
        setSlides(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // Component kaldırıldığında event listener'ı temizleyin.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Pencerenin genişliğine bağlı olarak uygulanacak stil.
  const backgroundStyle = {
    backgroundImage:
      windowWidth > 768
        ? `linear-gradient(rgba(247,244,244,0.87), rgba(247,244,244,0.87)), url(${bgCircleBlue})`
        : "none",
    backgroundPosition: windowWidth > 768 ? "-27% 0%" : "initial",
    backgroundSize: windowWidth > 768 ? "50%" : "initial",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div
      style={backgroundStyle}
      className="pb-44 max-2xl:pb-24 max-xl:pb-20 max-md:pb-10 mt-10 pt-1"
    >
      <div>
        <div className="text-8xl max-2xl:text-7xl max-xl:text-6xl max-md:text-[52px] font-bold text-lightBlue text-center mt-36 max-xl:mt-28 uppercase">
          {/* Diagnosis */}
        </div>
        <div className="text-6xl max-2xl:text-5xl max-xl:text-4xl uppercase text-center mt-5 max-xl:mt-4">
          {/* Solutions */}
        </div>
      </div>
      <div
        className="flex items-center justify-center gap-14 max-xl:gap-5 w-3/4 max-md:w-[100%] h-96 max-md:h-[270px] max-2xl:h-80 max-xl:h-72 bg-lightBlue mx-auto mt-10 rounded-3xl max-md:rounded-none"
        style={{
          backgroundImage: windowWidth > 768 ? `url(${bgCircle})` : "none",
          backgroundPosition: windowWidth > 768 ? "30px center" : "initial",
          backgroundSize: windowWidth > 768 ? "55% auto" : "initial",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="hidden max-md:block bg-white absolute w-full h-40" />
        <div className="w-[30%] max-md:hidden backdrop-blur-[6px] backdrop-brightness-[1.2] backdrop-contrast-[0.65] border-white border-2 py-6 max-2xl:py-4 px-4 rounded-2xl overflow-hidden text-center">
          {/* <div className="text-3xl max-2xl:text-[26px] max-xl:text-xl font-bold uppercase">
            Diagnotech summit
          </div> */}
          <div className="text-4xl max-2xl:text-3xl max-xl:text-2xl uppercase w-full">
            Events
          </div>
        </div>
        <div className="diagnosisSlider w-1/2 max-md:w-[85%]  h-4/5 max-xl:h-[70%] rounded-3xl overflow-hidden">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            loop={true}
            className="mySwiper"
          >
            {slides.map((slide, index) => (
              <SwiperSlide
                key={index}
                className="mySwiperSlide2"
                style={{
                  backgroundImage: `url(${slide.Image})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              ></SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Diagnosis;
