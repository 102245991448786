import React, { useEffect, useState } from "react";
import logo from "../../assets/symbols/vision-yeni.png";
import symbl from "../../assets/symbols/mavi-çizgi.png";
import woman from "../../assets/images/insan2.png";
import bgCircleBlue from "../../assets/symbols/daire---mavi.png";

const AboutDiagnotech = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Pencere yeniden boyutlandırıldığında bu işlev çağrılır.
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Component yüklendiğinde bir event listener ekleyin.
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Component kaldırıldığında event listener'ı temizleyin.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Pencerenin genişliğine bağlı olarak uygulanacak stil.
  const backgroundStyle = {
    backgroundImage:
      windowWidth < 768
        ? `linear-gradient(rgba(247,244,244,0.87), rgba(247,244,244,0.87)), url(${bgCircleBlue})`
        : "none",
    backgroundPosition: windowWidth < 768 ? "40vw 50%" : "initial",
    backgroundSize: windowWidth < 768 ? "130%" : "initial",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div style={backgroundStyle}>
      <div className="mt-28 flex flex-col items-center">
        <div className="w-1/3 max-md:w-2/3 scale-105">
          <img src={logo} alt="" className="w-full" />
        </div>
        <div className="w-1/3 max-md:hidden max-md:w-full scale-110 -mt-36 max-md:-mt-20 max-2xl:-mt-28 max-xl:-mt-20">
          <img src={symbl} alt="" className="w-full" />
        </div>
        <div className="-mt-52 max-md:mt-6 max-2xl:-mt-40 max-xl:-mt-32 w-2/3 max-md:w-full max-md:px-5 max-xl:w-[76%] text-center">
          Diagnotech aims to gather global Life Sciences professionals ranging
          from academic researchers, industry specialists, and routine
          healthcare officials in one pot and transform this power into
          producing smart and reliable IVD & research tools, products, and
          complete laboratory solutions that are available on a global scale. 
        </div>
        <div className="max-md:hidden mt-10 max-md:mt-7 text-6xl max-xl:text-5xl max-md:text-4xl text-lightBlue font-medium text-center">
          One company, multiple solutions
        </div>
      </div>
      <div className="hidden max-md:block bg-lightBlue h-[2px] max-md:w-[65%] mx-auto max-md:mt-8" />
      <div className="flex items-center px-28 max-md:px-2 max-md:-mt-12">
        <div className="flex-1 flex justify-center mt-32 max-2xl:mt-24 max-xl:mt-16">
          <div className="flex flex-col items-start bg-lightBlue max-md:bg-inherit py-10 px-20 max-2xl:py-5 max-2xl:px-9 max-xl:px-7 max-xl:py-3 rounded-3xl gap-7 max-xl:gap-2">
            <div className="font-bold text-4xl max-2xl:text-3xl max-xl:text-2xl max-md:text-xl uppercase text-white max-md:text-lightBlue max-md:text-center">
              COMPREHENSIVE PRODUCT PORTFOLIO AND INTEGRATED SOLUTIONS
            </div>
            <div className="font-medium max-md:font-normal text-xl max-2xl:text-lg text-white max-md:text-inherit max-xl:text-base max-md:text-center">
              Founded in 2019 and endorsed by thousands of professionals,
              Diagnotech stands out for its extensive product portfolio. From
              supporting academic research to facilitating routine health
              practices, we are dedicated to provide smart and reliable In Vitro
              Diagnostics (IVD) and Research tools. Explore our leading position
              as a one-stop-shop, offering products and solutions tailored to
              meet your needs.
            </div>
            {/* <button className="text-3xl max-2xl:text-2xl max-xl:text-xl font-semibold p-3 max-md:px-2 max-md:py-1 max-xl:p-2 rounded-xl bg-white text-lightBlue hover:shadow-xl shadow-gray-500 duration-300 hover:scale-95 max-md:mx-auto">
              Contact Us
            </button> */}
          </div>
        </div>
        <div className="flex-1 flex items-start justify-center max-md:hidden">
          <img
            src={`${process.env.PUBLIC_URL}/images/insan2.png`}
            alt=""
            className="w-full max-xl:h-[54vh] object-cover max-xl:ml-5"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutDiagnotech;
