import React from "react";
import aboutBanner from "../../assets/images/pexels-artem-podrez-5726794.jpg";
import symbl from "../../assets/symbols/beyaz-çizgi.png";

const AboutBanner = () => {
  return (
    <div className="flex flex-col items-center gap-9 bg-[#4bb7cf] text-white">
      <div className="w-full h-[15.625rem] max-xl:h-[14rem] max-md:h-[10rem] relative">
        <img
          src={aboutBanner}
          alt=""
          className="aboutBannerImg w-full h-full object-cover absolute"
          style={{ objectPosition: "0% 35%" }}
        />
        <div className="absolute w-full h-full top-0 left-0 flex flex-col items-center justify-center text-white z-10">
          <div className="text-lg">About Us</div>
          <div className="text-7xl max-xl:text-6xl max-md:text-5xl font-medium">
            Who We Are?
          </div>
        </div>
        <div className="absolute bg-black w-full h-full top-0 left-0 opacity-[35%]"></div>
      </div>
      <div className="px-44 max-xl:px-24 max-md:px-5 text-center text-xl max-md:text-lg font-medium">
        Diagnotech was established in 2019 with references from thousands of
        professionals in a <br className="max-md:hidden" /> wide range of
        portfolios, <br className="max-md:hidden" /> from academic research to
        routine health practice, smart and reliable IVD & Research tools, it is
        a leading biotechnology company offering products and complete
        laboratory solutions. 
        <div className="py-4 max-md:py-2" />
        As of July 2023, we have entered into a strategic
        <br className="max-md:hidden" /> partnership agreement with Procomcure,
        an Austria-based company. <br className="max-md:hidden" /> This
        collaboration opens up the potential for significant future projects in
        the field of diagnostic testing. <br className="max-md:hidden" />
        Procomcure has been at the forefront of providing testing services
        within Europe throughout the pandemic. <br className="max-md:hidden" />
        Notably, the company maintainsfull control over the entire process,
        <br className="max-md:hidden" /> from manufacturing the raw materials
        used in their diagnostic kits to producing the final
        <br className="max-md:hidden" /> diagnostic kit, all within their own
        facilities.
      </div>
      <div className="w-1/3 max-md:w-full scale-110 max-md:scale-95 -mt-44 max-xl:-mt-32 max-md:-mt-40 max-md:-mb-32 -mb-40 max-xl:-mb-28">
        <img src={symbl} alt="" className="w-full" />
      </div>
    </div>
  );
};

export default AboutBanner;
