import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/symbols/logo.png";
import logoWhite from "../../assets/symbols/beyaz.png";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineCaretDown, AiOutlineRight } from "react-icons/ai";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const Navbar = () => {
  const [selected, setSelected] = useState(null);
  const [subMenuSelected, setSubMenuSelected] = useState(null);
  const [subSubMenuSelected, setSubSubMenuSelected] = useState(null);
  const location = useLocation();
  const navRef = useRef(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [ProductCategorys, setProductCategory] = useState([]);

 //console.log(ProductCategorys);

  useEffect(() => {
    const fetchProductCategory = async () => {
      try {
        const response = await fetch(
          "https://cms.diagnotech.com.tr/Api/GetProductCategory"
        );
        const data = await response.json();

        const transformedData = data.map((category) => {
          if (category.SubCategory.length > 0) {
            const subSubMenu = category.SubCategory.map((subCategory) => ({
              title: subCategory.Name,
              path: `/products-and-solutions/${subCategory.Url}`,
            }));

            return {
              title: category.Name,
              subSubMenu,
            };
          } else {
            return {
              title: category.Name,
              path: `/products-and-solutions/${category.Url}`,
            };
          }
        });

        setProductCategory(transformedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProductCategory();
  }, []);

  const MENU_ITEMS = [
    { title: "Home", path: "/" },
    {
      title: "Product & Solutions",
      subMenu: ProductCategorys,
    },
    { title: "Partners", path: "/partners" },
    { title: "Quality", path: "/quality" },
    { title: "News", path: "/news" },
    { title: "About Us", path: "/about-us" },
  ];

  const MENU_ITEMS_MOBILE = [
    { title: "Home", path: "/" },
    {
      title: "Product & Solutions",
      subMenu: ProductCategorys,
    },
    { title: "Partners", path: "/partners" },
    { title: "Quality", path: "/quality" },
    { title: "News", path: "/news" },
    { title: "About Us", path: "/about-us" },
  ];

  // const renderSubMenu = (subMenu, depth = 0) => {
  //   return subMenu.map((subItem, subIndex) => (
  //     <div key={`subMenu-${depth}-${subIndex}`}>
  //       {subItem.subSubMenu ? (
  //         <button
  //           onClick={() => handleSubMenuClick(subIndex)}
  //           className="flex items-center px-4 py-3 w-full text-left"
  //         >
  //           {subItem.title}
  //           <AiOutlineRight
  //             className={`transform transition-transform ${
  //               subMenuSelected === subIndex ? "rotate-90" : ""
  //             }`}
  //           />
  //         </button>
  //       ) : (
  //         <Link
  //           to={subItem.path}
  //           className="block px-4 py-3"
  //           onClick={handleLinkClick}
  //         >
  //           {subItem.title}
  //         </Link>
  //       )}
  //       {subItem.subSubMenu && subMenuSelected === subIndex && (
  //         <div className="pl-4">
  //           {renderSubMenu(subItem.subSubMenu, depth + 1)}
  //         </div>
  //       )}
  //     </div>
  //   ));
  // };

  // const handleSubMenuClick = (index) => {
  //   if (subMenuSelected === index) {
  //     setSubMenuSelected(null);
  //   } else {
  //     setSubMenuSelected(index);
  //   }
  // };

  useEffect(() => {
    const currentPathIndex = MENU_ITEMS.findIndex(
      (item) => item.path === location.pathname
    );
    if (currentPathIndex !== -1) setSelected(currentPathIndex);
  }, [location.pathname]);

  useEffect(() => {
    function handleClickOutside(event) {
      // Tıklama Navbar dışında mı kontrol ediliyor
      if (navRef.current && !navRef.current.contains(event.target)) {
        setSubMenuSelected(null);
        setSubSubMenuSelected(null);
        setSelected(null);
      }
    }

    // Tıklama eventini ekliyoruz
    document.addEventListener("mousedown", handleClickOutside);

    // Component unmount olduğunda eventi kaldırıyoruz
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [navRef]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const isLightBg = ["/about-us", "/news/details"].includes(location.pathname);
  const pathsWithWhiteBg = [
    "/news",
    "/products-and-solutions",
    "/details",
    "/partners",
    "/quality",
  ];

  const isWhiteBg = pathsWithWhiteBg.some((path) =>
    location.pathname.includes(path)
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleSubMenu = (index) => {
    if (activeSubMenu === index) {
      setActiveSubMenu(null);
    } else {
      setActiveSubMenu(index);
    }
  };

  return (
    <div
      className={`relative ${
        isLightBg ? "bg-[#4bb7cf]" : isWhiteBg ? "bg-lightBody" : "bg-[#d3d1d1]"
      } ${!isLightBg && !isWhiteBg ? "shadow-xl" : ""}`}
    >
      <div className="flex justify-between items-center px-8 max-md:px-2 w-full -mt-9">
        <Link to="/" className="z-[90]">
          <div className="w-72 h-auto max-md:w-52">
            <img
              className="w-full h-full"
              src={isLightBg ? logoWhite : logo}
              alt="Logo"
            />
          </div>
        </Link>

        <nav className="hidden md:block z-50">
          <ul ref={navRef} className="flex items-center gap-1">
            {MENU_ITEMS.map((item, index) => (
              <li
                key={index}
                className={`relative group ml-3 cursor-pointer font-medium text-black/70 ${
                  isLightBg ? "text-white" : "text-inherit"
                } ${selected === index ? "scale-[1.04] text-black/100" : ""}`}
                onClick={() => {
                  setSelected(index);
                  setSubMenuSelected(null);
                  setSubSubMenuSelected(null);
                }}
                onMouseEnter={() => setSelected(index)}
                onMouseLeave={() => setSelected(null)}
              >
                <Link
                  to={item.path}
                  className={`${
                    selected === index
                      ? isLightBg
                        ? "border-b-[3px] border-white"
                        : "border-b-[3px] border-black"
                      : "hover:border-b-[3px] border-black"
                  }`}
                  onMouseEnter={() => setSelected(index)}
                >
                  {item.title}
                </Link>
                {item.subMenu && selected === index && (
                  <ul className="absolute z-30 -left-14 ml-6 pt-2 bg-gray-500 shadow-lg rounded-lg w-[130%] bg-opacity-40 backdrop-blur-[8px]">
                    {item.subMenu.map((subItem, subIndex) => (
                      <li
                        key={subIndex}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSubMenuSelected(subIndex);
                          setSubSubMenuSelected(null);
                        }}
                        className="relative pl-5 pr-3 py-3 rounded-lg cursor-pointer"
                        onMouseEnter={() => setSubMenuSelected(subIndex)}
                        onMouseLeave={() => setSubMenuSelected(null)}
                      >
                        <Link
                          onClick={() => {
                            if (subItem.path) {
                              setSubMenuSelected(null);
                              setSubSubMenuSelected(null);
                              setSelected(null);
                            }
                          }}
                          to={subItem.path}
                          className="flex items-center justify-between pl-1 font-medium text-base w-full text-white hover:scale-110 duration-200"
                          onMouseEnter={() => setSubMenuSelected(subIndex)}
                        >
                          {subItem.title}
                          {subItem.subSubMenu && (
                            <div className="text-2xl -mr-1"> {">"} </div>
                          )}
                          {/* {subItem.subSubMenu && <AiOutlineRight size={25} />} */}
                        </Link>

                        {subIndex !== item.subMenu.length - 1 && (
                          <div className="bg-white h-[1.5px] w-[96%] mt-3" />
                        )}

                        {subItem.subSubMenu && subMenuSelected === subIndex && (
                          <ul className="absolute left-full ml-0 -top-2 w-full bg-gray-500 shadow-lg rounded-lg bg-opacity-40 backdrop-blur-[8px]">
                            {subItem.subSubMenu.map(
                              (subSubItem, subSubIndex) => (
                                <li
                                  key={subSubIndex}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSubSubMenuSelected(subSubIndex);
                                  }}
                                  className="pl-3 pr-3 py-2 rounded-lg cursor-pointer flex flex-col items-center justify-center font-medium text-base w-full"
                                >
                                  <Link
                                    onClick={() => {
                                      if (subSubItem.path) {
                                        setSubMenuSelected(null);
                                        setSubSubMenuSelected(null);
                                        setSelected(null);
                                      }
                                    }}
                                    to={subSubItem.path}
                                    className="w-full text-white hover:scale-110 duration-200 text-center"
                                  >
                                    {subSubItem.title}
                                  </Link>

                                  {subSubIndex !==
                                    subItem.subSubMenu.length - 1 && (
                                    <div className="bg-white h-[1.5px] w-[96%] mt-3" />
                                  )}
                                </li>
                              )
                            )}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>

        {/* Mobile Menu Panel */}
        <nav className="text-white absolute w-full h-full top-10 left-0 z-50 hidden max-md:block">
          <div className="px-4 py-2 flex justify-end items-center">
            <button
              onClick={toggleMenu}
              className={`text-3xl md:hidden ${
                isLightBg ? "text-lightBody" : " text-[#4bb7cf]"
              }`}
            >
              <b>{isMenuOpen ? "✕" : "☰"}</b>
            </button>
          </div>
          <div
            className={`md:hidden ${
              isMenuOpen ? "block" : "hidden"
            } bg-lightBody text-lightBlue shadow-lg shadow-gray-500 min-h-screen`}
          >
            <ul className="flex flex-col">
              {MENU_ITEMS_MOBILE.map((item, index) => (
                <React.Fragment key={item.title}>
                  <li className="border-t border-white py-3 text-center font-bold">
                    {item.subMenu ? (
                      <button
                        className="px-4 py-2 w-full text-center font-bold flex items-center gap-2 justify-center"
                        onClick={() => handleSubMenu(index)}
                      >
                        {item.title} <AiOutlineCaretDown />
                      </button>
                    ) : (
                      <Link
                        to={item.path}
                        onClick={toggleMenu}
                        className="px-4 py-2 w-full text-center"
                      >
                        {item.title}
                      </Link>
                    )}
                    {activeSubMenu === index && item.subMenu && (
                      <ul className="flex flex-col bg-white py-2">
                        {item.subMenu.map((subItem) => (
                          <li
                            key={subItem.title}
                            className="border-t border-white text-center font-medium py-1"
                          >
                            {!subItem.subSubMenu ? (
                              <Link
                                to={subItem.path}
                                onClick={toggleMenu}
                                className="px-4 py-2 w-full text-center"
                              >
                                {subItem.title}
                              </Link>
                            ) : (
                              <React.Fragment>
                                <button
                                  onClick={() => handleSubMenu(index)}
                                  disabled
                                  className="px-4 py-2 w-full text-left opacity-60 flex items-center gap-2"
                                >
                                  {subItem.title} <AiOutlineCaretDown />
                                </button>
                                <ul className="flex flex-col">
                                  {subItem.subSubMenu.map((subSubItem) => (
                                    <li
                                      key={subSubItem.title}
                                      className="border-t border-white"
                                    >
                                      <Link
                                        to={subSubItem.path}
                                        onClick={toggleMenu}
                                        className="px-4 py-2 w-full text-left font-light"
                                      >
                                        {subSubItem.title}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </React.Fragment>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
