import React from "react";
import { Link } from "react-router-dom";

const ProductItem = ({ image, title, number, key, urlx }) => {
  return (
    <Link
       to={`/products-and-solutions/details/${urlx}`}
      className="flex flex-col h-full shadow-lg hover:shadow-gray-400 overflow-hidden rounded-xl duration-300 cursor-default"
    >
      <div className="flex-grow">
        <img
          src={image}
          alt={title}
          className="w-full h-44 max-md:h-32 lg:h-32 object-contain hover:scale-[1.065] duration-300"
        />
      </div>
      <div className="p-3 max-md:p-2 max-md:text-base text-center text-lg">
        <div>{title}</div>
        {/* <div className="mt-2">{number}</div> */}
      </div>
    </Link>
  );
};

export default ProductItem;
